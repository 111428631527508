import { SendersRepository } from '@/core/repositories/Senders.repository'
import { SenderPayloadT } from '../types/common.types'
import { ServerResponse } from '../types/Responses/common.responses'
import {
    SenderCreateResponse,
    SenderGetByIdResponse,
    SenderSearchResponse,
    SendersGetAllResponse,
    SendersGetCompanyScheduleResponse,
} from '../types/Responses/Senders.responses'

const sendersRepository = new SendersRepository()

const sendersService = {
    async getAll(
        page: number,
        exact_city_id?: number
    ): Promise<ServerResponse<SendersGetAllResponse>> {
        const sendersGetAllResponse = await sendersRepository.getAll(page, exact_city_id)
        return sendersGetAllResponse.data
    },

    async getById(sender_id: number): Promise<ServerResponse<SenderGetByIdResponse>> {
        const senderGetByIdResponse = await sendersRepository.getById(sender_id)
        return senderGetByIdResponse.data
    },

    async create(sender_payload: SenderPayloadT): Promise<ServerResponse<SenderCreateResponse>> {
        const senderCreateResponse = await sendersRepository.create(sender_payload)
        return senderCreateResponse.data
    },

    async update(sender_id: number, sender_payload: SenderPayloadT): Promise<void> {
        await sendersRepository.update(sender_id, sender_payload)
    },

    async delete(sender_id: number): Promise<void> {
        await sendersRepository.delete(sender_id)
    },

    async search(
        search_query: string,
        exact_city_id?: number
    ): Promise<ServerResponse<SenderSearchResponse>> {
        const sendersSearchResponse = await sendersRepository.search(search_query, exact_city_id)
        return sendersSearchResponse.data
    },

    async checkCompanySchedule(
        date: string
    ): Promise<ServerResponse<SendersGetCompanyScheduleResponse>> {
        const sendersGetAllResponse = await sendersRepository.checkCompanySchedule(date)
        return sendersGetAllResponse.data
    },
}

export default sendersService
