<template>
    <s-modal v-if="isOnLoadInstruction" @close="closeModal">
        <template v-slot:body>
            <div class="sidebarModal__header">
                <div class="header__text">
                    <p class="header__title">Скачать инструкцию</p>
                </div>
                <icon-close class="header__close" @click="closeModal" />
            </div>

            <div class="sidebarModal__body grid-2">
                <a
                    data-text="Инструкция"
                    href="/Инструкция к личному кабинету Spark.pdf"
                    download
                    class="Modal__link"
                >
                    <span class="hideble-content" :class="textClasses">Текущая инструкция</span>
                </a>

                <a
                    data-text="Инструкция"
                    href="/Инструкция FTL ЛК.pdf"
                    download
                    class="Modal__link"
                >
                    <span class="hideble-content" :class="textClasses">
                        Инструкция к FTL-заявкам
                    </span>
                </a>

                <a
                    data-text="Инструкция"
                    href="/Инструкция_к_претензиям.pdf"
                    download
                    class="Modal__link"
                >
                    <span class="hideble-content" :class="textClasses">
                        Инструкция к претензиям
                    </span>
                </a>
            </div>
        </template>
    </s-modal>

    <div class="SSideBar" :class="sideBarClasses">
        <div class="SSideBar__stickyWrapper">
            <div>
                <div class="SSideBar__logoWrap">
                    <icon-logo class="SSideBar__logo" @click="toggleSideBar" />
                </div>

                <ul class="SSideBar__links">
                    <router-link data-text="Главная" to="/" class="SSideBar__link">
                        <icon-home class="link-icon stroke" />
                        <li class="hideble-content" :class="textClasses">Главная</li>
                    </router-link>

                    <router-link
                        v-if="sideBarState.isBillingEnabled"
                        data-text="Калькулятор"
                        to="/calculator"
                        class="SSideBar__link"
                    >
                        <svg
                            class="link-icon fill"
                            fill="#333"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 460 460"
                            xml:space="preserve"
                        >
                            <g id="XMLID_241_">
                                <g>
                                    <path
                                        d="M369.635,0H90.365C73.595,0,60,13.595,60,30.365v399.27C60,446.405,73.595,460,90.365,460h279.27
                                        c16.77,0,30.365-13.595,30.365-30.365V30.365C400,13.595,386.405,0,369.635,0z M108.204,343.61v-43.196
                                        c0-3.451,2.797-6.248,6.248-6.248h43.196c3.451,0,6.248,2.797,6.248,6.248v43.196c0,3.451-2.797,6.248-6.248,6.248h-43.196
                                        C111.001,349.858,108.204,347.06,108.204,343.61z M108.204,256.61v-43.196c0-3.451,2.797-6.248,6.248-6.248h43.196
                                        c3.451,0,6.248,2.797,6.248,6.248v43.196c0,3.451-2.797,6.248-6.248,6.248h-43.196C111.001,262.858,108.204,260.06,108.204,256.61
                                        z M308.891,421H151.109c-11.046,0-20-8.954-20-20c0-11.046,8.954-20,20-20h157.782c11.046,0,20,8.954,20,20
                                        C328.891,412.046,319.937,421,308.891,421z M208.402,294.165h43.196c3.451,0,6.248,2.797,6.248,6.248v43.196
                                        c0,3.451-2.797,6.248-6.248,6.248h-43.196c-3.451,0-6.248-2.797-6.248-6.248v-43.196
                                        C202.154,296.963,204.951,294.165,208.402,294.165z M202.154,256.61v-43.196c0-3.451,2.797-6.248,6.248-6.248h43.196
                                        c3.451,0,6.248,2.797,6.248,6.248v43.196c0,3.451-2.797,6.248-6.248,6.248h-43.196C204.951,262.858,202.154,260.06,202.154,256.61
                                        z M345.548,349.858h-43.196c-3.451,0-6.248-2.797-6.248-6.248v-43.196c0-3.451,2.797-6.248,6.248-6.248h43.196
                                        c3.451,0,6.248,2.797,6.248,6.248v43.196h0C351.796,347.061,348.999,349.858,345.548,349.858z M345.548,262.858h-43.196
                                        c-3.451,0-6.248-2.797-6.248-6.248v-43.196c0-3.451,2.797-6.248,6.248-6.248h43.196c3.451,0,6.248,2.797,6.248,6.248v43.196h0
                                        C351.796,260.061,348.999,262.858,345.548,262.858z M354,149.637c0,11.799-9.565,21.363-21.363,21.363H127.364
                                        C115.565,171,106,161.435,106,149.637V62.363C106,50.565,115.565,41,127.364,41h205.273C344.435,41,354,50.565,354,62.363V149.637
                                        z"
                                    />
                                </g>
                            </g>
                        </svg>

                        <li class="hideble-content" :class="textClasses">Калькулятор</li>
                    </router-link>

                    <router-link data-text="История заказов" to="/history" class="SSideBar__link">
                        <icon-document-text class="link-icon stroke" />
                        <li class="hideble-content" :class="textClasses">История заказов</li>
                    </router-link>

                    <router-link data-text="Профиль" to="/profile" class="SSideBar__link">
                        <icon-person class="link-icon stroke" />
                        <li class="hideble-content" :class="textClasses">Профиль</li>
                    </router-link>

                    <div @click="openModal" class="SSideBar__link">
                        <icon-download class="link-icon fill" />
                        <li class="hideble-content" :class="textClasses">Скачать инструкцию</li>
                    </div>

                    <router-link
                        data-text="Претензии и Жалобы"
                        to="/pretensions"
                        class="SSideBar__link"
                    >
                        <icon-document-text class="link-icon stroke" />
                        <li class="hideble-content" :class="textClasses">Претензии и Жалобы</li>
                    </router-link>

                    <router-link
                        data-text="Документы"
                        to="/reconciliation-reports"
                        class="SSideBar__link"
                    >
                        <icon-reports class="link-icon stroke" />
                        <li class="hideble-content" :class="textClasses">Документы</li>
                    </router-link>

                    <div data-text="Поддержка" class="SSideBar__link" @click="openJiraHelp">
                        <icon-chat class="link-icon" />
                        <li class="hideble-content" :class="textClasses">Поддержка</li>
                    </div>
                </ul>
            </div>

            <div v-if="sideBarState.isOpened" class="SSideBar__landing">
                <a href="https://myapp.spark.kz" target="_blank">
                    <order-type
                        title="Всегда под рукой"
                        subtitle="скачивай бесплатное приложение"
                        image-width="60%"
                        img="Phone.png"
                        type="Landing"
                        image-right="-15px"
                        image-bottom="20px"
                        is-relative-content
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref } from 'vue'

import iReversedLogo from '@/assets/icons/LogoReversed.svg'
import iDocumentText from '@/assets/icons/DocumentText.svg'
import iReports from '@/assets/icons/Reports.svg'
import iDownload from '@/assets/icons/Download.svg'
import iPerson from '@/assets/icons/Person.svg'
import iHome from '@/assets/icons/Home.svg'
import iChat from '@/assets/icons/Chat.svg'

import { useScreenWidth } from '@/common/composable/useScreenWidth'
import SModal from '@/common/components/SModal'
import { useModal } from '@/common/composable/useModal'
import iClose from '@/assets/icons/Close.svg'
import OrderType from '@/modules/CreateOrder/components/OrderType/index.vue'

import { UsersRealisation } from '@/core/realisations/Users.realisation'

const EXPAND_BREAKPOINT = 1087

export default defineComponent({
    components: {
        'icon-logo': iReversedLogo,
        'icon-document-text': iDocumentText,
        'icon-person': iPerson,
        'icon-reports': iReports,
        'icon-download': iDownload,
        's-modal': SModal,
        'icon-close': iClose,
        'icon-home': iHome,
        // 'icon-bell': iBell,
        'icon-chat': iChat,
        OrderType,
    },
    setup() {
        const { screenWidth } = useScreenWidth()
        const isOnLoadInstruction = ref(false)
        const { openModal, closeModal } = useModal(isOnLoadInstruction)

        const usersAPI = new UsersRealisation()

        const sideBarState = reactive({
            isOpened: true,
            isBillingEnabled: false,
        })

        const checkIfBillingEnabled = async () => {
            try {
                const response = await usersAPI.getProfile()

                if (response.data.company.billing_enabled) {
                    sideBarState.isBillingEnabled = true
                } else {
                    sideBarState.isBillingEnabled = false
                }
            } catch (error) {
                console.error(error)
            }
        }

        const isLockSidebar = computed(() => {
            if (screenWidth.value < EXPAND_BREAKPOINT) return true
            return false
        })

        const toggleSideBar = () => {
            if (!sideBarState.isOpened && isLockSidebar.value) return
            sideBarState.isOpened = !sideBarState.isOpened
        }

        const sideBarClasses = computed(() => {
            return {
                expanded: sideBarState.isOpened,
            }
        })

        const textClasses = computed(() => {
            return {
                hided: !sideBarState.isOpened,
                visible: sideBarState.isOpened,
            }
        })

        const trackScreenWidth = () => {
            if (sideBarState.isOpened && isLockSidebar.value) {
                toggleSideBar()
            }
        }

        const resizeListener = () => {
            window.addEventListener('resize', trackScreenWidth)
        }

        onMounted(() => {
            resizeListener()
            checkIfBillingEnabled()
        })

        onBeforeUnmount(() => {
            window.removeEventListener('resize', trackScreenWidth)
        })

        const openJiraHelp = () => {
            const element = document.querySelector('chat-widget')

            if (!element) {
                console.warn('Jira widget not founded')
                return
            }

            const shadow = element.shadowRoot

            if (!shadow) {
                console.warn('Jira widget not founded')
                return
            }

            const button = shadow.querySelector('.chat-icon-button')

            if (!button) {
                console.warn('Jira widget not founded')
                return
            }

            button.click()
        }

        return {
            sideBarState,
            //
            toggleSideBar,
            sideBarClasses,
            textClasses,
            screenWidth,
            isOnLoadInstruction,
            openModal,
            closeModal,
            openJiraHelp,
        }
    },
})
</script>

<style lang="scss" src="./index.scss" />
