
import { computed, defineComponent, onMounted, reactive } from 'vue'

import SButton from '@/common/components/SButton/index.vue'
import SRadioGroup from '@/common/components/SRadioGroup/index.vue'
import STextArea from '@/common/components/STextArea/index.vue'

import { MappedSurveyT, MappedVariantT, SendAnswerPayloadT } from '@/core/types/Survey.types'
import { SurveyRealisation } from '@/core/realisations/Survey.realisation'

const SELF_VARIANT = 'ДРУГОЕ'

export default defineComponent({
    components: {
        's-button': SButton,
        's-radio-group': SRadioGroup,
        's-text-area': STextArea,
    },
    setup() {
        const survey = new SurveyRealisation()

        const state = reactive({
            is_loading: false,
            is_loaded: false,
            is_sending: false,
            is_completed: false,
            survey: null as MappedSurveyT | null,
            selected_variant: null as MappedVariantT | null,
            comment: null as string | null,
        })

        const loadSurvey = async () => {
            try {
                state.is_loading = true

                const response = await survey.getSurvey()
                if (!response.data || !response.data.variants) return

                const variants = response.data.variants.map((variant) => {
                    return {
                        id: variant.id,
                        name: variant.title,
                    }
                })

                state.survey = {
                    id: response.data.id,
                    title: response.data.title,
                    variants,
                    description: response.data.description,
                }

                state.is_loaded = true
            } catch (error) {
                console.error(error)
            } finally {
                state.is_loading = false
            }
        }

        onMounted(loadSurvey)

        const isSelfVariant = computed(() => {
            if (!state.selected_variant) return false
            if (state.selected_variant.name.toUpperCase() === SELF_VARIANT) {
                return true
            }
            return false
        })

        const sendAnswer = async () => {
            if (
                state.is_sending ||
                !state.survey ||
                !state.selected_variant ||
                (isSelfVariant.value && !state.comment)
            )
                return

            try {
                state.is_sending = true

                if (isSelfVariant.value) {
                    const payload: SendAnswerPayloadT = {
                        surveyId: state.survey.id,
                        surveyVariantId: state.selected_variant.id,
                        comment: state.comment,
                    }

                    await survey.sendAnswer(payload)
                } else {
                    const payload: SendAnswerPayloadT = {
                        surveyId: state.survey.id,
                        surveyVariantId: state.selected_variant.id,
                        comment: null,
                    }

                    await survey.sendAnswer(payload)
                }

                state.is_completed = true
            } catch (error) {
                console.error(error)
            } finally {
                state.is_sending = false
            }
        }

        const cancelAnswer = async () => {
            if (state.is_sending) return
            state.is_completed = true
        }

        return { state, isSelfVariant, sendAnswer, cancelAnswer }
    },
})
