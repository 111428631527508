import { InvoicesI } from '../interfaces/Invoices.interface'
import { invoicesService } from '../services/Invoices.service'
import {
    FTLExcelParams,
    FTLOrderQueryFilters,
    LTLExcelParams,
    LTLQueryFilters,
} from '../types/History.types'
import { SendToEmailsPayload, UpdateInvoicePayloadT } from '../types/Invoices.types'
import {
    FTLOrderResponseT,
    FTLOrdersResponseT,
    LTLInvoiceResponseT,
    LTLInvoicesResponseT,
    LTLOrdersResponseT,
    LTLOrderResponseT,
    LTLInvoiceStatusesResponseT,
    LTLInvoiceForPrintResponseT,
    LTLInvoicePlacesForPrintResponseT,
    FTLInvoiceForPrintResponseT,
    LTLOrderForUpdateResponseT,
    LTLArrivingInvoicesResponseT,
    LTLSendedInvoicesResponseT,
    SendToEmailsResponseT,
    LTLOrdersDownloadExcelResponseT,
    FTLOrdersDownloadExcelResponseT,
    getTemperatureRegimesResponseT,
    LTLOrderPlacesForPrintResponseT,
    LTLOrderIdDownloadExcelResponseT,
} from '../types/Responses/Invoices.responses'

export class InvoicesRealisation implements InvoicesI {
    getLTLArrivingInvoices(params: LTLQueryFilters): Promise<LTLArrivingInvoicesResponseT> {
        return invoicesService.getLTLArrivingInvoices(params)
    }

    getLTLSendedInvoices(params: LTLQueryFilters): Promise<LTLSendedInvoicesResponseT> {
        return invoicesService.getLTLSendedInvoices(params)
    }

    getLTLInvoices(params: LTLQueryFilters): Promise<LTLInvoicesResponseT> {
        return invoicesService.getLTLInvoices(params)
    }

    getLTLInvoice(invoice_id: string): Promise<LTLInvoiceResponseT> {
        return invoicesService.getLTLInvoice(invoice_id)
    }

    getInvoiceForPrint(invoice_id: string): Promise<LTLInvoiceResponseT> {
        return invoicesService.getInvoiceForPrint(invoice_id)
    }

    getLTLInvoiceStatuses(invoice_number: string): Promise<LTLInvoiceStatusesResponseT> {
        return invoicesService.getLTLInvoiceStatuses(invoice_number)
    }

    getLTLOrders(params: LTLQueryFilters): Promise<LTLOrdersResponseT> {
        return invoicesService.getLTLOrders(params)
    }

    exportExcelLTLInvoices(params: LTLExcelParams): Promise<LTLOrdersDownloadExcelResponseT> {
        return invoicesService.exportExcelLTLInvoices(params)
    }

    exportExcelFTLOrders(params: FTLExcelParams): Promise<FTLOrdersDownloadExcelResponseT> {
        return invoicesService.exportExcelFTLOrders(params)
    }

    exportExcelLTLOrderId(orderId: number): Promise<LTLOrderIdDownloadExcelResponseT> {
        return invoicesService.exportExcelLTLOrderId(orderId)
    }

    getLTLOrder(order_id: number, params: any): Promise<LTLOrderResponseT> {
        return invoicesService.getLTLOrder(order_id, params)
    }

    getLTLOrderForPrint(order_id: number): Promise<LTLOrderResponseT> {
        return invoicesService.getLTLOrderForPrint(order_id)
    }

    getLTLOrderForUpdate(order_id: number): Promise<LTLOrderForUpdateResponseT> {
        return invoicesService.getLTLOrderForUpdate(order_id)
    }

    getFTLOrders(params: FTLOrderQueryFilters): Promise<FTLOrdersResponseT> {
        return invoicesService.getFTLOrders(params)
    }

    getFTLOrder(order_id: number): Promise<FTLOrderResponseT> {
        return invoicesService.getFTLOrder(order_id)
    }

    getLTLInvoiceForPrint(invoice_number: string): Promise<LTLInvoiceForPrintResponseT> {
        return invoicesService.getLTLInvoiceForPrint(invoice_number)
    }

    getFTLInvoiceForPrint(invoice_number: string): Promise<FTLInvoiceForPrintResponseT> {
        return invoicesService.getFTLInvoiceForPrint(invoice_number)
    }

    getLTLInvoicesForPrint(order_id: number): Promise<LTLInvoiceForPrintResponseT[]> {
        return invoicesService.getLTLInvoicesForPrint(order_id)
    }

    createLTLOrder(order_payload: any): Promise<void> {
        return invoicesService.createLTLOrder(order_payload)
    }

    createFTLOrder(order_payload: any): Promise<void> {
        return invoicesService.createFTLOrder(order_payload)
    }

    uploadFiles(form_data: FormData): Promise<any> {
        return invoicesService.uploadFiles(form_data)
    }

    deleteFile(id: number): Promise<void> {
        return invoicesService.deleteFile(id)
    }

    updateLTLOrder(order_id: number, order_payload: any): Promise<void> {
        return invoicesService.updateLTLOrder(order_id, order_payload)
    }

    sendInvoice(pdf: string, shouldBeSentToOwner: boolean, emails: string[]): Promise<void> {
        return invoicesService.sendInvoice(pdf, shouldBeSentToOwner, emails)
    }

    getInvoicePlaces(invoice_id: number): Promise<LTLInvoicePlacesForPrintResponseT> {
        return invoicesService.getInvoicePlaces(invoice_id)
    }

    getOrderPlaces(order_id: number): Promise<LTLOrderPlacesForPrintResponseT> {
        return invoicesService.getOrderPlaces(order_id)
    }

    cancelLTLOrder(order_id: number): Promise<void> {
        return invoicesService.cancelLTLOrder(order_id)
    }

    cancelFTLOrder(order_id: number): Promise<void> {
        return invoicesService.cancelFTLOrder(order_id)
    }

    cancelInvoice(invoice_id: number): Promise<void> {
        return invoicesService.cancelInvoice(invoice_id)
    }

    confirmFTLOrder(order_id: number): Promise<void> {
        return invoicesService.confirmFTLOrder(order_id)
    }

    createExcel(payload_formdata: FormData): Promise<void> {
        return invoicesService.createExcel(payload_formdata)
    }

    sendToEmails(payload: SendToEmailsPayload): Promise<SendToEmailsResponseT> {
        return invoicesService.sendToEmails(payload)
    }

    updateInvoice(invoice_id: number, payload: UpdateInvoicePayloadT): Promise<void> {
        return invoicesService.updateInvoice(invoice_id, payload)
    }

    getTemperatureRegimes(): Promise<getTemperatureRegimesResponseT> {
        return invoicesService.getTemperatureRegimes()
    }
}
