import SendersI from '../interfaces/Senders.interface'
import sendersService from '../services/Senders.service'
import { SenderPayloadT } from '../types/common.types'

import { ServerResponse } from '../types/Responses/common.responses'
import {
    SenderCreateResponse,
    SenderGetByIdResponse,
    SenderSearchResponse,
    SendersGetAllResponse,
    SendersGetCompanyScheduleResponse,
} from '../types/Responses/Senders.responses'

export class SendersRealisation implements SendersI {
    async getAll(
        page: number,
        exact_city_id?: number
    ): Promise<ServerResponse<SendersGetAllResponse>> {
        const sendersGetAllResponse = await sendersService.getAll(page, exact_city_id)
        return sendersGetAllResponse
    }

    async getById(sender_id: number): Promise<ServerResponse<SenderGetByIdResponse>> {
        const senderGetByIdResponse = await sendersService.getById(sender_id)
        return senderGetByIdResponse
    }

    async create(sender_payload: SenderPayloadT): Promise<ServerResponse<SenderCreateResponse>> {
        const senderCreateResponse = await sendersService.create(sender_payload)
        return senderCreateResponse
    }

    async update(sender_id: number, sender_payload: SenderPayloadT): Promise<void> {
        await sendersService.update(sender_id, sender_payload)
    }

    async delete(sender_id: number): Promise<void> {
        await sendersService.delete(sender_id)
    }

    async search(
        search_query: string,
        exact_city_id?: number
    ): Promise<ServerResponse<SenderSearchResponse>> {
        const senderSearchResponse = await sendersService.search(search_query, exact_city_id)
        return senderSearchResponse
    }

    async checkCompanySchedule(
        date: string
    ): Promise<ServerResponse<SendersGetCompanyScheduleResponse>> {
        const sendersGetAllResponse = await sendersService.checkCompanySchedule(date)
        return sendersGetAllResponse
    }
}
