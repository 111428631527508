
import { defineComponent, onBeforeMount, reactive } from 'vue'

export default defineComponent({
    props: {
        value: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: null,
        },
        isAutoResize: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value', 'change', 'enter'],
    setup(props, { emit }) {
        const state = reactive({
            height: null as number | null,
        })

        const autoResize = (target: HTMLTextAreaElement) => {
            const new_height = target.scrollHeight
            target.style.height = `${new_height}px`

            state.height = new_height + 13 * 2
        }

        const emitInput = (event: Event) => {
            const target = event.target as HTMLTextAreaElement
            emit('update:value', target.value)

            if (props.isAutoResize) {
                autoResize(target)
            }
        }

        const emitChange = (event: Event) => {
            const target = event.target as HTMLTextAreaElement
            emit('change', target.value)
        }

        const emitEnter = () => {
            emit('enter')
        }

        onBeforeMount(() => {
            state.height = props.height
        })

        return { state, emitInput, emitChange, emitEnter }
    },
})
