
import { defineComponent, PropType } from 'vue'

import SRadio from '@/common/components/SRadio/index.vue'

export default defineComponent({
    components: { 's-radio': SRadio },
    props: {
        value: {
            type: Object,
            default: null,
        },
        options: {
            type: Array as PropType<
                {
                    id: number
                    label: string
                    name?: string
                    is_additional_name?: boolean
                    name_additional?: string
                }[]
            >,
            default: () => [],
        },
        theme: {
            type: String,
            default: 'primary',
        },
        nameAdditional: {
            type: String,
            default: null,
        },
        disabledOptionId: {
            type: Number,
            default: null,
        },
        disabledOptionsIds: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        isHorizontal: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value', 'change'],
    setup(props, { emit }) {
        const groupId: string = Math.random() * (999 - 0) + 0 + ''

        const emitChange = (option: { id: number; name: string }) => {
            if (option.id !== props.disabledOptionId) {
                emit('update:value', option)
                emit('change', option)
            }
        }

        const getLabel = (option: any) => {
            return props.nameAdditional && option.is_additional_name
                ? `${option.name} (${props.nameAdditional})`
                : option.name
        }

        return { groupId, emitChange, getLabel }
    },
})
