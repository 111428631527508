
import { defineComponent } from 'vue'

import GreenGradient from '@/assets/icons/gradients/green-gradient-provider.svg'
import RedGradient from '@/assets/icons/gradients/red-gradient-provider.svg'

export default defineComponent({
    components: {
        'green-gradient': GreenGradient,
        'red-gradient': RedGradient,
    },
})
