import { reactive, computed } from 'vue'
import { useLocalStorage } from 'vue-composable'

// constants
import {
    AUTHORIZATION_STORAGE_TOKEN,
    DEFAULT_AUTHORIZATION_STATE,
} from '@/core/constants/Authorization.constants'

// types
import {
    AuthorizationStateT,
    AuhtorizationAppEnvironmentsT,
    AuthorizationSignInPayloadT,
    AuthorizationCredentialsT,
} from '@/core/types/Authorization.types'

// other modules
import { http } from '@/core/classes/HTTP.class'
import { AuthorizationRealisation } from '@/core/realisations/Authorization.realisation'
import { resetImpersonate } from './useImpersonate'

// localStorage
const { storage } = useLocalStorage(AUTHORIZATION_STORAGE_TOKEN)

/**
 * Инициализация state
 * Проверяем localStorage, если там есть информация возвращаем ее, если нет возвращаем дефолтное значение
 */
const initializeState = (): AuthorizationStateT => {
    if (!storage.value) return DEFAULT_AUTHORIZATION_STATE
    if (typeof storage.value === 'object') {
        const state_from_storage: AuthorizationStateT = storage.value as AuthorizationStateT
        return state_from_storage
    }

    return DEFAULT_AUTHORIZATION_STATE
}

/**
 * Экземпляр state
 * Либо из localStorage, либо дефолтное значение
 */
const authorizationState: AuthorizationStateT = reactive({
    ...initializeState(),
})

/**
 * Функция для установки state, localStorage, http.default.headers.Authorization
 * @param state
 */
function setAuthorizationState(state: AuthorizationStateT) {
    // Уставливаем значения для state
    authorizationState.access_token = state.access_token
    authorizationState.refresh_token = state.refresh_token
    authorizationState.token_type = state.token_type
    authorizationState.expires_in = state.expires_in

    // Устанавливаем state в localStorage
    storage.value = JSON.stringify(state)
    // Устанавливаем access_token в axios.defaults.headers.Authorization
    http.setAuthorization(state.access_token, state.token_type)
}

/**
 * Функция для сброса state, localStorage, http.default.headers.Authorization
 */
function resetAuthorizationState() {
    // Сбрасываем значения для state
    authorizationState.access_token = ''
    authorizationState.refresh_token = ''
    authorizationState.token_type = ''
    authorizationState.expires_in = 0

    // Чистим state в localStorage
    storage.value = ''
    // Чистим axios.defaults.headers.Authorization
    http.removeAuthorization()
}

/**
 * Функция для получение environments
 */
function getAppEnvironments(): AuhtorizationAppEnvironmentsT {
    const grant_type = process.env.VUE_APP_API_GRANT_TYPE || ''
    const client_id = Number(process.env.VUE_APP_API_CLIENT_ID) || 0
    const client_secret = process.env.VUE_APP_API_CLIENT_SECRET || ''

    return { grant_type, client_id, client_secret }
}

/**
 * Основная экспортируемая функция
 */
export function useAuthorization() {
    /**
     * Авторизация
     * @param payload
     */
    const authorizationAuthorize = async (payload: AuthorizationSignInPayloadT) => {
        const { grant_type, client_id, client_secret } = getAppEnvironments()

        const credentials: AuthorizationCredentialsT = {
            username: payload.login,
            password: payload.password,
            grant_type,
            client_id,
            client_secret,
        }

        const authorizationRealisation = new AuthorizationRealisation()
        const authorizationResponse = await authorizationRealisation.authorize(credentials)

        setAuthorizationState(authorizationResponse)
    }

    /**
     * Сброс авторизации
     */
    const authorizationUnauthorize = () => {
        resetAuthorizationState()
        resetImpersonate()
    }

    /**
     * Реактивный геттер для проверки авторизован пользователь или нет
     */
    const isAuthenticated = computed(() => Boolean(authorizationState.access_token))

    return {
        resetAuthorizationState,
        setAuthorizationState,
        authorizationAuthorize,
        authorizationUnauthorize,
        isAuthenticated,
    }
}
