import { Ref } from 'vue'

export const useModal = (initialValue: Ref<boolean>) => {
    const openModal = () => {
        initialValue.value = true
    }

    const closeModal = () => {
        initialValue.value = false
    }

    return {
        openModal,
        closeModal,
    }
}
