import {
    AdditionalServicesT,
    NewLTLAdditionalServicesT,
    NewLTLReceiverAdditionalServicesT,
    OptionT,
} from '../types/common.types'
// ???
export const PAYMENT_TYPE_OPTIONS: OptionT<number>[] = [
    { id: 1, name: 'Оплата отправителем', value: 1, is_additional_name: true },
    { id: 2, name: 'Оплата получателем', value: 2 },
    { id: 3, name: 'Оплата третьим лицом', value: 3 },
]
// ???
export const PAYMENT_METHOD_OPTIONS: OptionT<number>[] = [
    { id: 1, name: 'Оплата перечислением на счёт', value: 3 },
    { id: 2, name: 'Оплата наличными', value: 2 },
    { id: 4, name: 'Платежи на Kaspi.kz', value: 4 },
]

export const PAYMENT_METHOD_OPTIONS_PVZ: OptionT<number>[] = [
    { id: 1, name: 'Оплата перечислением на счёт', value: 3 },
    { id: 4, name: 'Платежи на Kaspi.kz', value: 4 },
]

export const PAYMENT_METHOD_OPTIONS_ON_PAYER_SELECTED: OptionT<number>[] = [
    { id: 1, name: 'Оплата перечислением на счёт', value: 3 },
]

export const SHIPMENT_TYPE_OPTIONS_ONLY_AUTO: OptionT<number>[] = [
    { id: 1, name: 'Авто', value: 1 },
]

export const SHIPMENT_TYPE_OPTIONS: OptionT<number>[] = [
    { id: 1, name: 'Авто', value: 1 },
    { id: 2, name: 'Авиа', value: 2 },
]

export const EMPTY_ADDITIONAL_SERVICES: AdditionalServicesT = {
    hasRisingToTheFloor: false,
    hasCar: false,
    hasSoftPackage: false,
    hasManipulator: false,
    hasCrane: false,
    hasHydraulicTrolley: false,
    hasGrid: false,
    hasLoader: false,
    hasPallet: false,
}

export const NEW_LTL_EMPTY_ADDITIONAL_SERVICES: NewLTLAdditionalServicesT = {
    hasCrane: false,
    hasHydraulicTrolley: false,
    hasLoader: false,
    hasManipulator: false,
}

export const NEW_LTL_RECEIVER_ADDITIONAL_SERVICES: NewLTLReceiverAdditionalServicesT = {
    hasRisingToTheFloor: false,
    hasCar: false,
    hasCrane: false,
    hasHydraulicTrolley: false,
    hasLoader: false,
    hasManipulator: false,
}

export const PLACES_TOKENS = ['место', 'места', 'мест']
export const POINTS_TOKENS = ['пункт', 'пункта', 'пунктов']
export const INVOICES_TOKENS = ['накладная', 'накладные', 'накладных']
export const USERS_TOKENS = ['пользователь', 'пользователя', 'пользователей']
export const MINUTES_TOKENS = ['минуту', 'минуты', 'минут']

export const OFFER_LINK = 'https://spark.kz/offer/'
