import { useLocalStorage } from 'vue-composable'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { http } from '@/core/classes/HTTP.class'
import { useAuthorization } from '@/common/composable/useAuthorization'
import { AUTHORIZATION_STORAGE_TOKEN } from '@/core/constants/Authorization.constants'
import { AuthorizationStateT } from '@/core/types/Authorization.types'

import { UsersRealisation } from '@/core/realisations/Users.realisation'

import useAccount from '@/common/composable/useAccount'

export const authMiddleware = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const { isAuthenticated } = useAuthorization()

    if (to.meta.requiresAuth && !isAuthenticated.value) {
        next({ name: 'Auth' })
        return
    }

    if (isAuthenticated && !http.authorizationHeader) {
        const account = useAccount()
        await account.initialize()

        const { storage } = useLocalStorage(AUTHORIZATION_STORAGE_TOKEN)
        if (typeof storage.value === 'object') {
            const authroizationState: AuthorizationStateT = storage.value as AuthorizationStateT
            http.setAuthorization(authroizationState.access_token, authroizationState.token_type)
        }
    }

    const usersAPI = new UsersRealisation()

    if (to.name === 'Calculator') {
        const response = await usersAPI.getProfile()

        if (!response.data.company.billing_enabled) {
            if (from && from.name) {
                next({ name: from.name })
                return
            }

            next({ name: 'Create' })
            return
        }
    }

    next()
}
