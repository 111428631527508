import { UsersRepository } from '../repositories/Users.repository'
import { ServerResponse } from '../types/Responses/common.responses'
import { ProfileResponseT, StaffUsersResponseT } from '../types/Responses/Users.responses'
import { PasswordPayloadT, ProfileCardPayload, StaffUserT } from '../types/Users.types'

const usersRepository = new UsersRepository()

const usersService = {
    async getProfile(): Promise<ServerResponse<ProfileResponseT>> {
        const profileResponse = await usersRepository.getProfile()
        return profileResponse.data
    },

    async changePassword(payload: PasswordPayloadT): Promise<void> {
        await usersRepository.changePassword(payload)
        return
    },

    async updateProfile(payload: ProfileCardPayload): Promise<void> {
        await usersRepository.updateProfile(payload)
        return
    },

    async getStaffUsers(page: number): Promise<StaffUsersResponseT> {
        const usersResponse = await usersRepository.getStaffUsers(page)
        return usersResponse.data
    },

    async createStaffUser(user_payload: StaffUserT): Promise<ServerResponse<StaffUserT>> {
        const usersResponse = await usersRepository.createStaffUser(user_payload)
        return usersResponse.data
    },

    async updateStaffUser(
        user_id: number,
        user_payload: StaffUserT
    ): Promise<ServerResponse<StaffUserT>> {
        const usersResponse = await usersRepository.updateStaffUser(user_id, user_payload)
        return usersResponse.data
    },

    async deleteUser(user_id: number) {
        await usersRepository.deleteUser(user_id)
    },

    async createProposal(payload: any) {
        await usersRepository.createProposal(payload)
    }
}

export default usersService
