import { AxiosResponse } from 'axios'
import { http } from '../classes/HTTP.class'
import { ServerResponse } from '../types/Responses/common.responses'
import { ProfileResponseT, StaffUsersResponseT } from '../types/Responses/Users.responses'
import { PasswordPayloadT, ProfileCardPayload, StaffUserT } from '../types/Users.types'

enum UsersRepositoryRoutesEnum {
    'PROFILE' = '/v2/users/profile',
    'CHANGE_PASS' = '/v2/password/update',
    'UPDATE_PROFILE' = '/v2/users/update',
    'GET_STAFF' = '/staff',
    'CREATE_STAFF' = '/staff',
    'UPDATE_STAFF' = '/staff',
    'DELETE_USER' = '/staff',
    'CREATE_PROPOSAL' = '/proposals'
}

const BASE_URL = process.env.VUE_APP_GATEWAY_URL

export class UsersRepository {
    getProfile(): Promise<AxiosResponse<ServerResponse<ProfileResponseT>>> {
        return http.get(UsersRepositoryRoutesEnum.PROFILE)
    }

    changePassword(payload: PasswordPayloadT): Promise<AxiosResponse<void>> {
        return http.post(`${BASE_URL}/gateway/users/change-password`, {
            password: payload.password,
        })
    }

    updateProfile(payload: ProfileCardPayload): Promise<AxiosResponse<void>> {
        return http.put(UsersRepositoryRoutesEnum.UPDATE_PROFILE, payload)
    }

    getStaffUsers(page: number): Promise<AxiosResponse<StaffUsersResponseT>> {
        const params = {
            page,
        }

        return http.get(UsersRepositoryRoutesEnum.GET_STAFF, { params })
    }

    createStaffUser(user_payload: StaffUserT): Promise<AxiosResponse<ServerResponse<StaffUserT>>> {
        return http.post(UsersRepositoryRoutesEnum.CREATE_STAFF, user_payload)
    }

    updateStaffUser(
        user_id: number,
        user_payload: StaffUserT
    ): Promise<AxiosResponse<ServerResponse<StaffUserT>>> {
        return http.put(`${UsersRepositoryRoutesEnum.UPDATE_STAFF}/${user_id}`, user_payload)
    }

    deleteUser(user_id: number): Promise<AxiosResponse<void>> {
        return http.delete(`${UsersRepositoryRoutesEnum.DELETE_USER}/${user_id}`)
    }

    createProposal(payload: any): Promise<AxiosResponse<void>> {
        return http.post(UsersRepositoryRoutesEnum.CREATE_PROPOSAL, payload)
    }
}
