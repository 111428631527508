// import { mask } from 'maska'

// import { PHONE_OPTIONS } from '@/core/constants/Phone.constants'
// import { detectOptionByPhoneNumber } from '@/common/helpers'

// const DEFAULT_TOKENS = {
//     '#': { pattern: /[0-9]/ },
//     X: { pattern: /[0-9a-zA-Z]/ },
//     S: { pattern: /[a-zA-Z]/ },
//     A: { pattern: /[a-zA-Z]/, uppercase: true },
//     a: { pattern: /[a-zA-Z]/, lowercase: true },
//     '!': { escape: true },
//     '*': { repeat: true },
// }

export default {
    methods: {
        toPhone: function (phone_number: string | null): string {
            if (!phone_number) return 'Номер телефона не указан'
            return phone_number
        },
    },
}
