import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import { SenderPayloadT } from '@/core/types/common.types'

import { ServerResponse } from '../types/Responses/common.responses'
import {
    SenderCreateResponse,
    SenderGetByIdResponse,
    SenderSearchResponse,
    SendersGetAllResponse,
    SendersGetCompanyScheduleResponse,
} from '../types/Responses/Senders.responses'

enum SendersRepositoryRoutesEnum {
    'GET_ALL' = '/sender',
    'GET_BY_ID' = '/sender',
    'CREATE' = '/sender',
    'UPDATE' = '/sender',
    'DELETE' = '/sender',
    'SEARCH' = '/sender/search',
    'GET_COMPANY_SCHEDULE' = '/companies/schedule',
}

export class SendersRepository {
    /**
     * Получение списка Отправителей
     * @param page Страница
     * @returns Promise<AxiosResponse<ServerResponse<SendersGetAllResponse>>>
     */
    getAll(
        page: number,
        city_id?: number
    ): Promise<AxiosResponse<ServerResponse<SendersGetAllResponse>>> {
        const params = {
            page_number: page,
            city_id,
        }

        return http.get(SendersRepositoryRoutesEnum.GET_ALL, { params })
    }

    /**
     * Получение отправителя по ID
     * @param sender_id ID отправителя
     * @returns Promise<AxiosResponse<ServerResponse<SenderGetByIdResponse>>>
     */
    getById(sender_id: number): Promise<AxiosResponse<ServerResponse<SenderGetByIdResponse>>> {
        return http.get(`${SendersRepositoryRoutesEnum.GET_BY_ID}/${sender_id}`)
    }

    /**
     * Создание отправителя
     * @param sender_payload Данные для создания отправителя
     * @returns Promise<AxiosResponse<void>>
     */
    create(
        sender_payload: SenderPayloadT
    ): Promise<AxiosResponse<ServerResponse<SenderCreateResponse>>> {
        return http.post(SendersRepositoryRoutesEnum.CREATE, sender_payload)
    }

    /**
     * Редактирование отправителя
     * @param sender_id ID отправителя
     * @param sender_payload Данные для редактирования отправителя
     * @returns Promise<AxiosResponse<void>>
     */
    update(sender_id: number, sender_payload: SenderPayloadT): Promise<AxiosResponse<void>> {
        return http.put(`${SendersRepositoryRoutesEnum.UPDATE}/${sender_id}`, sender_payload)
    }

    /**
     * Удаление отправителя
     * @param sender_id ID отправителя
     * @returns Promise<AxiosResponse<void>>
     */
    delete(sender_id: number): Promise<AxiosResponse<void>> {
        return http.delete(`${SendersRepositoryRoutesEnum.DELETE}/${sender_id}`)
    }

    /**
     * Поиск отправителей
     * @param search_query (наименование/телефон/улица) отправителя
     * @returns Promise<AxiosResponse<ServerResponse<SenderSearchResponse>>>
     */
    search(
        search_query: string,
        exact_city_id?: number
    ): Promise<AxiosResponse<ServerResponse<SenderSearchResponse>>> {
        return http.post(`${SendersRepositoryRoutesEnum.SEARCH}`, {
            param: search_query,
            exact_city_id,
        })
    }

    /**
     * Проверка расписания компании
     * @param date Дата
     * @returns Promise<AxiosResponse<ServerResponse<SendersGetCompanyScheduleResponse>>>
     */
    checkCompanySchedule(
        date: string
    ): Promise<AxiosResponse<ServerResponse<SendersGetCompanyScheduleResponse>>> {
        return http.get(`${SendersRepositoryRoutesEnum.GET_COMPANY_SCHEDULE}/${date}/open-today`, {
            params: { date },
        })
    }
}
