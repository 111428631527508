import { AxiosResponse } from 'axios'
import { http } from '../classes/HTTP.class'
import { ServerResponse } from '../types/Responses/common.responses'
import { SendAnswerPayloadT, SurveyT } from '../types/Survey.types'

const BASE_URL = process.env.VUE_APP_SURVEY_URL

export class SurveyRepository {
    getSurvey(): Promise<AxiosResponse<ServerResponse<SurveyT>>> {
        return http.get(`${BASE_URL}/surveys`)
    }

    sendAnswer(payload: SendAnswerPayloadT): Promise<AxiosResponse<void>> {
        return http.post(`${BASE_URL}/surveys`, payload)
    }
}
