
import { defineComponent } from 'vue'

export default defineComponent({
    emits: ['close'],
    setup(_, { emit }) {
        const closeModal = () => {
            emit('close')
        }

        return {
            closeModal,
        }
    },
})
