import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
// import store from './store'

import 'normalize.css'
import '@/assets/styles/scss/index.scss'
// import '@/assets/styles/css/bootstrap-grid.min.css'

import container from '@/plugins/container'

// libs & components
import Multiselect from '@vueform/multiselect'
import Notifications from '@kyvg/vue3-notification'
import tooltip from '@/common/directives/tooltip.directive'

// mixins
import declOfNum from '@/common/mixins/declOfNum.mixin'
import toPhone from '@/common/mixins/toPhone.mixin'

// socket client
// Нужно для работы laravel echo
// см (src/common/composable/useSockets)
import { io } from 'socket.io-client'
//@ts-ignore
window.io = io

const app = createApp(App)

// app.use(store)
app.use(router)
app.use(container)
app.use(Notifications)

app.component('multiselect', Multiselect)

app.directive('tooltip', tooltip)

app.mixin(declOfNum)
app.mixin(toPhone)

// app.config.errorHandler = (error: unknown) => {
//     console.log('FROM ERROR HANDLER')
//     console.log(error)
// }

app.mount('#app')
