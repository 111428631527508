/**
 * Composable function for working with permissions and roles
 */
import { reactive, toRaw } from 'vue'

// types
import { GatewayPermissionT, GatewayRoleT } from '@/core/types/Authorization.types'

// constants
import { USER_STORAGE_TOKEN } from '@/core/constants/Authorization.constants'

// composable
import { useLocalStorage } from 'vue-composable'
import { AuthorizationRealisation } from '@/core/realisations/Authorization.realisation'

// interfaces
interface AccountI {
    permissions: GatewayPermissionT[]
    roles: GatewayRoleT[]
    user_id: number | null
    user_name: string | null
    user_email: string | null
    is_loading: boolean
    is_failed: boolean
}

const account: AccountI = reactive({
    permissions: [],
    roles: [],
    user_id: null,
    user_name: null,
    user_email: null,
    is_loading: false,
    is_failed: false,
})

export default function () {
    async function loadAccount() {
        try {
            account.is_loading = true

            const authorizationRealisation = new AuthorizationRealisation()

            const response = await authorizationRealisation.getInfo()
            account.permissions = response.data.permissions
            account.roles = response.data.roles

            account.user_id = response.data.info.id
            account.user_name = response.data.info.name
            account.user_email = response.data.info.email

            const { storage } = useLocalStorage(USER_STORAGE_TOKEN)
            storage.value = JSON.stringify(toRaw(account))

            account.is_failed = false
        } catch (error) {
            console.error(error)
            account.is_failed = true
        } finally {
            account.is_loading = false
        }
    }

    async function initialize(): Promise<void> {
        const { storage } = useLocalStorage(USER_STORAGE_TOKEN)

        if (storage.value) {
            let storage_account = undefined as AccountI | undefined

            if (typeof storage.value === 'object') {
                storage_account = storage.value
            }

            if (typeof storage.value === 'string' && storage.value !== 'undefined') {
                storage_account = JSON.parse(storage.value)
            }

            if (storage_account) {
                account.permissions = storage_account.permissions
                account.roles = storage_account.roles
                account.user_id = storage_account.user_id
                account.user_name = storage_account.user_name
                account.user_email = storage_account.user_email

                account.is_loading = false
                account.is_failed = false
                return
            }
        }

        return loadAccount()
    }

    function reset() {
        const { storage } = useLocalStorage(USER_STORAGE_TOKEN)
        storage.value = ''

        account.permissions = []
        account.roles = []
        account.user_id = null
        account.user_email = null
        account.user_name = null
    }

    function haveRole(role_id?: number): boolean {
        if (!role_id) return true
        return account.roles.some((role) => role.id === role_id)
    }

    function haveRoles(roles?: number[]): boolean {
        if (!roles || roles.length === 0) return true

        const roles_ids = account.roles.map((role) => role.id)
        return roles_ids.some((role) => roles.includes(role))
    }

    function havePermission(permission_name?: string): boolean {
        if (!permission_name) return true
        return account.permissions.some((permission) => permission.name === permission_name)
    }

    function havePermissions(permissions?: string[]): boolean {
        if (!permissions || permissions.length === 0) return true

        const permissions_names = account.permissions.map((permission) => permission.name)
        return permissions_names.some((permission) => permissions.includes(permission))
    }

    return {
        state: account,
        initialize,
        reset,
        loadAccount,
        haveRole,
        haveRoles,
        havePermission,
        havePermissions,
    }
}
