
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        value: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'radio label',
        },
        name: {
            type: String,
            default: null,
        },
        theme: {
            type: String,
            default: 'primary',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isHorizontal: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['change'],
    setup(_, { emit }) {
        const emitChange = (event: Event) => {
            const target = event.target as HTMLInputElement
            const value = JSON.parse(target.value)
            emit('change', value)
        }

        return { emitChange }
    },
})
