import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_s_loader = _resolveComponent("s-loader")!

  return (_openBlock(), _createBlock("button", {
    class: "SButton",
    disabled: _ctx.disabled,
    type: _ctx.type,
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitClick && _ctx.emitClick(...args)))
  }, [
    (!_ctx.loading)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_openBlock(), _createBlock(_component_s_loader, { key: 1 }))
  ], 8, ["disabled", "type"]))
}