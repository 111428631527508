import { invoicesRepository } from '../repositories/Invoices.repository'
import {
    FTLExcelParams,
    FTLOrderQueryFilters,
    LTLExcelParams,
    LTLQueryFilters,
} from '../types/History.types'
import { SendToEmailsPayload, UpdateInvoicePayloadT } from '../types/Invoices.types'
import {
    FTLInvoiceForPrintResponseT,
    FTLOrderResponseT,
    FTLOrdersDownloadExcelResponseT,
    FTLOrdersResponseT,
    LTLArrivingInvoicesResponseT,
    LTLInvoiceForPrintResponseT,
    LTLInvoicePlacesForPrintResponseT,
    LTLInvoiceResponseT,
    LTLInvoicesResponseT,
    LTLInvoiceStatusesResponseT,
    LTLOrderForUpdateResponseT,
    LTLOrderResponseT,
    LTLOrdersDownloadExcelResponseT,
    LTLOrdersResponseT,
    LTLSendedInvoicesResponseT,
    SendToEmailsResponseT,
    getTemperatureRegimesResponseT,
    LTLOrderPlacesForPrintResponseT,
    LTLOrderIdDownloadExcelResponseT,
} from '../types/Responses/Invoices.responses'

export const invoicesService = {
    async getLTLArrivingInvoices(params: LTLQueryFilters): Promise<LTLArrivingInvoicesResponseT> {
        const LTLInvoices = await invoicesRepository.getLTLArrivingInvoices(params)
        return LTLInvoices.data
    },

    async getLTLSendedInvoices(params: LTLQueryFilters): Promise<LTLSendedInvoicesResponseT> {
        const LTLInvoices = await invoicesRepository.getLTLSendedInvoices(params)
        return LTLInvoices.data
    },

    async getLTLInvoices(params: LTLQueryFilters): Promise<LTLInvoicesResponseT> {
        const LTLInvoices = await invoicesRepository.getLTLInvoices(params)
        return LTLInvoices.data
    },

    async getLTLInvoice(invoice_id: string): Promise<LTLInvoiceResponseT> {
        const LTLInvoice = await invoicesRepository.getLTLInvoice(invoice_id)
        return LTLInvoice.data
    },

    async getInvoiceForPrint(invoice_id: string): Promise<LTLInvoiceResponseT> {
        const LTLInvoice = await invoicesRepository.getInvoiceForPrint(invoice_id)
        return LTLInvoice.data
    },

    async getLTLInvoiceStatuses(invoice_number: string): Promise<LTLInvoiceStatusesResponseT> {
        const LTLInvoiceStatuses = await invoicesRepository.getLTLInvoiceStatuses(invoice_number)
        return LTLInvoiceStatuses.data
    },

    async getLTLOrders(params: LTLQueryFilters): Promise<LTLOrdersResponseT> {
        const LTLOrders = await invoicesRepository.getLTLOrders(params)
        return LTLOrders.data
    },

    async exportExcelLTLInvoices(params: LTLExcelParams): Promise<LTLOrdersDownloadExcelResponseT> {
        const response = await invoicesRepository.exportExcelLTLInvoices(params)
        return response.data
    },

    async exportExcelFTLOrders(params: FTLExcelParams): Promise<FTLOrdersDownloadExcelResponseT> {
        const response = await invoicesRepository.exportExcelFTLOrders(params)
        return response.data
    },

    async exportExcelLTLOrderId(orderId: number): Promise<LTLOrderIdDownloadExcelResponseT> {
        const response = await invoicesRepository.exportExcelLTLOrderId(orderId)
        return response.data
    },

    async getLTLOrder(order_id: number, params: any): Promise<LTLOrderResponseT> {
        const LTLOrder = await invoicesRepository.getLTLOrder(order_id, params)
        return LTLOrder.data
    },

    async getLTLOrderForPrint(order_id: number): Promise<LTLOrderResponseT> {
        const LTLOrder = await invoicesRepository.getLTLOrderForPrint(order_id)
        return LTLOrder.data
    },

    async getLTLOrderForUpdate(order_id: number): Promise<LTLOrderForUpdateResponseT> {
        const response = await invoicesRepository.getLTLOrderForUpdate(order_id)
        return response.data
    },

    async getFTLOrders(params: FTLOrderQueryFilters): Promise<FTLOrdersResponseT> {
        const FTLOrders = await invoicesRepository.getFTLOrders(params)
        return FTLOrders.data
    },

    async getFTLOrder(order_id: number): Promise<FTLOrderResponseT> {
        const FTLOrder = await invoicesRepository.getFTLOrder(order_id)
        return FTLOrder.data
    },

    async getLTLInvoiceForPrint(invoice_number: string): Promise<LTLInvoiceForPrintResponseT> {
        const LTLInvoiceForPrint = await invoicesRepository.getLTLInvoiceForPrint(invoice_number)
        return LTLInvoiceForPrint.data.data
    },

    async getFTLInvoiceForPrint(invoice_number: string): Promise<FTLInvoiceForPrintResponseT> {
        const FTLInvoiceForPrint = await invoicesRepository.getFTLInvoiceForPrint(invoice_number)
        return FTLInvoiceForPrint.data
    },

    async getLTLInvoicesForPrint(order_id: number): Promise<LTLInvoiceForPrintResponseT[]> {
        const LTLInvoicesForPrint = await invoicesRepository.getLTLInvoicesForPrint(order_id)
        return LTLInvoicesForPrint.data.data
    },

    async createLTLOrder(order_payload: any): Promise<void> {
        await invoicesRepository.createLTLOrder(order_payload)
    },

    async createFTLOrder(order_payload: any): Promise<void> {
        await invoicesRepository.createFTLOrder(order_payload)
    },

    async uploadFiles(form_data: FormData): Promise<any> {
        const files = await invoicesRepository.uploadFiles(form_data)
        return files.data
    },

    async deleteFile(id: number): Promise<void> {
        await invoicesRepository.deleteFile(id)
    },

    async updateLTLOrder(order_id: number, order_payload: any): Promise<void> {
        await invoicesRepository.updateLTLOrder(order_id, order_payload)
    },

    async sendInvoice(pdf: string, shouldBeSentToOwner: boolean, emails: string[]): Promise<void> {
        await invoicesRepository.sendInvoice(pdf, shouldBeSentToOwner, emails)
    },

    async getInvoicePlaces(invoice_id: number): Promise<LTLInvoicePlacesForPrintResponseT> {
        const placesResponse = await invoicesRepository.getInvoicePlaces(invoice_id)
        return placesResponse.data
    },

    async getOrderPlaces(order_id: number): Promise<LTLOrderPlacesForPrintResponseT> {
        const placesResponse = await invoicesRepository.getOrderPlaces(order_id)
        return placesResponse.data
    },

    async cancelLTLOrder(order_id: number): Promise<void> {
        await invoicesRepository.cancelLTLOrder(order_id)
        return
    },

    async cancelFTLOrder(order_id: number): Promise<void> {
        await invoicesRepository.cancelFTLOrder(order_id)
        return
    },

    async cancelInvoice(invoice_id: number): Promise<void> {
        await invoicesRepository.cancelInvoice(invoice_id)
        return
    },

    async confirmFTLOrder(order_id: number): Promise<void> {
        await invoicesRepository.confirmFTLOrder(order_id)
        return
    },

    async createExcel(payload_formdata: FormData): Promise<void> {
        await invoicesRepository.createExcel(payload_formdata)
        return
    },

    async sendToEmails(payload: SendToEmailsPayload): Promise<SendToEmailsResponseT> {
        const response = await invoicesRepository.sendToEmails(payload)
        return response.data
    },

    async updateInvoice(invoice_id: number, payload: UpdateInvoicePayloadT): Promise<void> {
        const response = await invoicesRepository.updateInvoice(invoice_id, payload)
        return response.data
    },

    async getTemperatureRegimes(): Promise<getTemperatureRegimesResponseT> {
        const response = await invoicesRepository.getTemperatureRegimes()
        return response.data
    },
}
