/**
 * * Плагин, добавляющий глобально Контейнер с реализациями интерфейсов
 */

import { App } from 'vue'

import { DIContainer } from '@/core/classes/DIContainer.class'

import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { SendersRealisation } from '@/core/realisations/Senders.realisation'
import { ReceiversRealisation } from '@/core/realisations/Receivers.realisation'
import { AuthorizationRealisation } from '@/core/realisations/Authorization.realisation'
import { UsersRealisation } from '@/core/realisations/Users.realisation'

export default {
    install: (app: App): void => {
        const container = new DIContainer()

        container.add('auth', new AuthorizationRealisation())
        container.add('invoices', new InvoicesRealisation())
        container.add('senders', new SendersRealisation())
        container.add('receivers', new ReceiversRealisation())
        container.add('users', new UsersRealisation())

        app.config.globalProperties.$container = container
        app.provide('container', container)
    },
}
