import jwt from 'jsonwebtoken'

import useAccount from '@/common/composable/useAccount'

const SCRIPT_SRC = 'https://chat-api.spartez-software.com/chat-widget.js'
const AUTO_LOGIN_SECRET = 'MTUwLDIxOSwxMjksOCw2NCwxMjIsOTUsMjU0LDc3LDIwNiwzNywyNDcsNjgsNzAsNDksMTA2'

export default function () {
    const { state } = useAccount()

    const createToken = async (): Promise<string> => {
        const email = state.user_email
        const displayName = state.user_name

        const token = await jwt.sign({
            email,
            displayName,
        }, AUTO_LOGIN_SECRET, { algorithm: 'HS256' })

        return token
    }

    const onScriptLoad = async () => {
        const chatWidget  = document.querySelector('chat-widget')
        if (!chatWidget) return

        // @ts-ignore
        chatWidget.onload = async function () {
            console.log('Chat Widget loaded')

            const token = await createToken()
            // @ts-ignore
            await chatWidget.loginWithToken(token)
        }
    }

    const install = () => {
        const script = document.createElement('script')
        script.id = 'jira-widget'
        script.src = SCRIPT_SRC

        script.onerror = (error) => {
            console.error('Jira widget script install error')
            console.error(error)
        }

        document.head.append(script)
        onScriptLoad()
    }

    const destroy = () => {
        const script = document.head.querySelector('script#jira-widget')
        if (!script) return
        script.remove()
    }

    return { install, destroy }
}