import { reactive, toRaw, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useAuthorization } from './useAuthorization'
import { useLocalStorage } from 'vue-composable'

import { IMPERSONATE_STORAGE_TOKEN } from '@/core/constants/Authorization.constants'

const { storage } = useLocalStorage(IMPERSONATE_STORAGE_TOKEN)

interface ImpersonateStateI {
    interval: number | undefined
    is_active: boolean
    auth_time: Date | null
    time: number
    token: string | null
}

const impersonate_state: ImpersonateStateI = reactive({
    interval: undefined,
    is_active: false,
    auth_time: null,
    time: 0,
    token: null,
})

export function resetImpersonate() {
    clearInterval(impersonate_state.interval)
    impersonate_state.interval = undefined
    impersonate_state.is_active = false
    impersonate_state.auth_time = null
    impersonate_state.time = 0
    impersonate_state.token = null

    storage.value = ''
}

const INTERVAL_DELAY = 10000

export default function (activate_watch = true) {
    const route = useRoute()
    const router = useRouter()

    const { setAuthorizationState, resetAuthorizationState } = useAuthorization()

    function impersonateQuit() {
        resetAuthorizationState()
        resetImpersonate()

        router.push('/auth')
    }

    function calculateMinutesRemaining() {
        if (!impersonate_state.auth_time) {
            return 0
        }

        const now = new Date()
        const auth_time = impersonate_state.auth_time

        const diff_ms = auth_time.getTime() - now.getTime()
        const diff_minutes = Math.round(((diff_ms % 86400000) % 3600000) / 60000)

        impersonate_state.time = diff_minutes

        // console.log(diff_minutes)

        if (impersonate_state.time <= 0) {
            clearInterval(impersonate_state.interval)
            impersonateQuit()
        }
    }

    function impersonate(token: string, expires_in: number) {
        resetAuthorizationState()

        setAuthorizationState({
            access_token: token,
            expires_in,
            refresh_token: '',
            token_type: 'Bearer',
        })

        impersonate_state.is_active = true
        impersonate_state.token = token
        impersonate_state.auth_time = new Date(expires_in)

        storage.value = JSON.stringify(toRaw(impersonate_state))

        clearInterval(impersonate_state.interval)
        calculateMinutesRemaining()

        // @ts-ignore
        impersonate_state.interval = setInterval(calculateMinutesRemaining, INTERVAL_DELAY)

        router.replace('/')
    }

    function checkImpersonate() {
        if (!impersonate_state.is_active) {
            if (!storage.value) return

            if (typeof storage.value === 'object') {
                const state_from_storage: ImpersonateStateI = storage.value as ImpersonateStateI

                if (
                    state_from_storage.is_active &&
                    state_from_storage.token &&
                    state_from_storage.auth_time
                ) {
                    impersonate_state.is_active = true
                    impersonate_state.auth_time = new Date(state_from_storage.auth_time)
                    impersonate_state.token = state_from_storage.token

                    calculateMinutesRemaining()
                    // @ts-ignore
                    impersonate_state.interval = setInterval(
                        calculateMinutesRemaining,
                        INTERVAL_DELAY
                    )
                }
            }
        }
    }

    if (activate_watch) {
        checkImpersonate()

        watch(
            () => route.query,
            (query) => {
                const impersonate_token = query.impersonate_token
                if (!impersonate_token) return

                impersonate(impersonate_token.toString(), new Date().getTime() + 30 * 60000)
            }
        )
    }

    return { impersonate_state, impersonateQuit }
}
