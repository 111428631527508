import ReceiversI from '../interfaces/Receivers.interface'
import receiversService from '../services/Receivers.service'
import { ReceiverPayloadT } from '../types/common.types'
import { ServerResponse } from '../types/Responses/common.responses'
import {
    PayersResponse,
    ReceiverCreateResponse,
    ReceiversGetAllFTLResponse,
    ReceiversGetAllLTLResponse,
    ReceiversGetByIdResponse,
    ReceiversGetPeriodsLTLResponse,
    ReceiversSearchResponse,
} from '../types/Responses/Receivers.responses'

export class ReceiversRealisation implements ReceiversI {
    async getAllLTL(
        page: number,
        sender_city_id: number
    ): Promise<ServerResponse<ReceiversGetAllLTLResponse>> {
        const receiversGetAllLTLResponse = receiversService.getAllLTL(page, sender_city_id)
        return receiversGetAllLTLResponse
    }

    async getPeriods(): Promise<ServerResponse<ReceiversGetPeriodsLTLResponse>> {
        const periods = receiversService.getPeriods()
        return periods
    }

    static async getPayers(
        company_id: number,
        page: number
    ): Promise<ServerResponse<PayersResponse>> {
        const payersResponse = receiversService.getPayers(company_id, page)
        return payersResponse
    }

    async getAllFTL(
        page: number,
        exact_city_id?: number
    ): Promise<ServerResponse<ReceiversGetAllFTLResponse>> {
        const receiversGetAllFTLResponse = receiversService.getAllFTL(page, exact_city_id)
        return receiversGetAllFTLResponse
    }

    async getList(params: {
        page_number: number
        city_id?: number
    }): Promise<ReceiversGetAllFTLResponse> {
        const receiversGetAllFTLResponse = receiversService.getList(params)
        return receiversGetAllFTLResponse
    }

    async getById(receiver_id: number): Promise<ReceiversGetByIdResponse> {
        const receiverGetByIdResponse = receiversService.getById(receiver_id)
        return receiverGetByIdResponse
    }

    async create(
        receiver_payload: ReceiverPayloadT
    ): Promise<ServerResponse<ReceiverCreateResponse>> {
        const receiverCreateResponse = receiversService.create(receiver_payload)
        return receiverCreateResponse
    }

    async update(receiver_id: number, receiver_payload: ReceiverPayloadT): Promise<void> {
        await receiversService.update(receiver_id, receiver_payload)
    }

    async updateLTL(receiver_id: number, receiver_payload: ReceiverPayloadT): Promise<void> {
        await receiversService.updateLTL(receiver_id, receiver_payload)
    }

    async delete(receiver_id: number): Promise<void> {
        await receiversService.delete(receiver_id)
    }

    async search(
        search_query: string,
        exact_city_id?: number
    ): Promise<ServerResponse<ReceiversSearchResponse>> {
        const receiversSearchResponse = await receiversService.search(search_query, exact_city_id)
        return receiversSearchResponse
    }

    async searchLTL(
        search_query: string,
        sender_city_id: number
    ): Promise<ServerResponse<ReceiversSearchResponse>> {
        const receiversSearchResponse = await receiversService.searchLTL(
            search_query,
            sender_city_id
        )
        return receiversSearchResponse
    }
}
