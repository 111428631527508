import { AxiosResponse } from 'axios'
import { authorizationRepository } from '@/core/repositories/Authorization.respository'

import {
    AuthorizationCredentialsT,
    AuthorizationStateT,
    AuthorizationConfirmRecoveryPayloadT,
    RegisterPayloadT,
    GetInfoResponseT,
} from '@/core/types/Authorization.types'

import {
    AuthorizationConfirmRecoveryResponseT,
    AuthorizationRecoveryResponseT,
    BillingPeriodsResponseT,
    BusinessModelsResponseT,
} from '@/core/types/Responses/Authorization.responses'

export const authorizationService = {
    async authorize(credentials: AuthorizationCredentialsT): Promise<AuthorizationStateT> {
        const authorizationResponse: AxiosResponse<AuthorizationStateT> =
            await authorizationRepository.signin(credentials)

        return authorizationResponse.data
    },

    async recovery(email: string): Promise<AuthorizationRecoveryResponseT> {
        const authroizationRecoveryResponse: AxiosResponse<AuthorizationRecoveryResponseT> =
            await authorizationRepository.recovery(email)
        return authroizationRecoveryResponse.data
    },

    async confirmRecoveryCode(
        payload: AuthorizationConfirmRecoveryPayloadT
    ): Promise<AuthorizationConfirmRecoveryResponseT> {
        const authroizationConfirmRecoveryResponse: AxiosResponse<AuthorizationConfirmRecoveryResponseT> =
            await authorizationRepository.confirmRecoveryCode(payload)
        return authroizationConfirmRecoveryResponse.data
    },

    async getBusinessModels(): Promise<BusinessModelsResponseT> {
        const response = await authorizationRepository.getBusinessModels()
        return response.data
    },

    async getBillingPeriods(): Promise<BillingPeriodsResponseT> {
        const response = await authorizationRepository.getBillingPeriods()
        return response.data
    },

    async registerCompany(payload: RegisterPayloadT): Promise<void> {
        const response = await authorizationRepository.registerCompany(payload)
        return response.data
    },

    async getInfo(): Promise<GetInfoResponseT> {
        const response = await authorizationRepository.getInfo()
        return response.data
    },
}
