import UsersI from '../interfaces/Users.interface'
import usersService from '../services/Users.service'
import { ServerResponse } from '../types/Responses/common.responses'
import { ProfileResponseT, StaffUsersResponseT } from '../types/Responses/Users.responses'
import { PasswordPayloadT, ProfileCardPayload, StaffUserT } from '../types/Users.types'

export class UsersRealisation implements UsersI {
    async getProfile(): Promise<ServerResponse<ProfileResponseT>> {
        const profileResponse = await usersService.getProfile()
        return profileResponse
    }

    async changePassword(payload: PasswordPayloadT): Promise<void> {
        await usersService.changePassword(payload)
        return
    }

    async updateProfile(payload: ProfileCardPayload): Promise<void> {
        await usersService.updateProfile(payload)
        return
    }

    async getStaffUsers(page: number): Promise<StaffUsersResponseT> {
        const usersResponse = await usersService.getStaffUsers(page)
        return usersResponse
    }

    async createStaffUser(user_payload: StaffUserT): Promise<ServerResponse<StaffUserT>> {
        const usersResponse = await usersService.createStaffUser(user_payload)
        return usersResponse
    }

    async updateStaffUser(
        user_id: number,
        user_payload: StaffUserT
    ): Promise<ServerResponse<StaffUserT>> {
        const usersResponse = await usersService.updateStaffUser(user_id, user_payload)
        return usersResponse
    }

    async deleteUser(user_id: number): Promise<void> {
        await usersService.deleteUser(user_id)
    }

    async createProposal(payload: any): Promise<void> {
        await usersService.createProposal(payload)
    }
}
