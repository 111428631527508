import { ReceiversRepository } from '../repositories/Receivers.repository'
import { ReceiverPayloadT } from '../types/common.types'
import { ServerResponse } from '../types/Responses/common.responses'
import {
    PayersResponse,
    ReceiverCreateResponse,
    ReceiversGetAllFTLResponse,
    ReceiversGetAllLTLResponse,
    ReceiversGetByIdResponse,
    ReceiversGetPeriodsLTLResponse,
    ReceiversSearchResponse,
} from '../types/Responses/Receivers.responses'

const receiversRepository = new ReceiversRepository()

const receiversService = {
    async getAllLTL(
        page: number,
        sender_city_id: number
    ): Promise<ServerResponse<ReceiversGetAllLTLResponse>> {
        const receiversGetAllLTLResponse = await receiversRepository.getAllLTL(page, sender_city_id)
        return receiversGetAllLTLResponse.data
    },

    async getPeriods(): Promise<ServerResponse<ReceiversGetPeriodsLTLResponse>> {
        const periods = await receiversRepository.getPeriods()
        return periods.data
    },

    async getPayers(company_id: number, page: number): Promise<ServerResponse<PayersResponse>> {
        const payersResponse = await receiversRepository.getPayers(company_id, page)
        return payersResponse.data
    },

    async getAllFTL(
        page: number,
        exact_city_id?: number
    ): Promise<ServerResponse<ReceiversGetAllFTLResponse>> {
        const receiversGetAllFTLResponse = await receiversRepository.getAllFTL(page, exact_city_id)
        return receiversGetAllFTLResponse.data
    },

    async getList(params: {
        page_number: number
        city_id?: number
    }): Promise<ReceiversGetAllFTLResponse> {
        const receiversGetAllFTLResponse = await receiversRepository.getList(params)
        return receiversGetAllFTLResponse.data.data
    },

    async getById(receiver_id: number): Promise<ReceiversGetByIdResponse> {
        const receiverGetByIdResponse = await receiversRepository.getById(receiver_id)
        return receiverGetByIdResponse.data
    },

    async create(
        receiver_payload: ReceiverPayloadT
    ): Promise<ServerResponse<ReceiverCreateResponse>> {
        const receiverCreateResponse = await receiversRepository.create(receiver_payload)
        return receiverCreateResponse.data
    },

    async update(receiver_id: number, receiver_payload: ReceiverPayloadT): Promise<void> {
        await receiversRepository.update(receiver_id, receiver_payload)
    },

    async updateLTL(receiver_id: number, receiver_payload: ReceiverPayloadT): Promise<void> {
        await receiversRepository.updateLTL(receiver_id, receiver_payload)
    },

    async delete(receiver_id: number): Promise<void> {
        await receiversRepository.delete(receiver_id)
    },

    async search(
        search_query: string,
        exact_city_id?: number
    ): Promise<ServerResponse<ReceiversSearchResponse>> {
        const receiversSearchResponse = await receiversRepository.search(
            search_query,
            exact_city_id
        )
        return receiversSearchResponse.data
    },

    async searchLTL(
        search_query: string,
        sender_city_id: number
    ): Promise<ServerResponse<ReceiversSearchResponse>> {
        const receiversSearchResponse = await receiversRepository.searchLTL(
            search_query,
            sender_city_id
        )
        return receiversSearchResponse.data
    },
}

export default receiversService
