import { onBeforeUnmount, onMounted, ref } from 'vue'

export const useScreenWidth = () => {
    if (!window || !window.screen || !window.screen.width) return 0

    const screenWidth = ref(0)
    const getScreenWidth = () => {
        screenWidth.value = window.screen.width
    }

    const initializeScreenWidth = () => {
        window.addEventListener('resize', () => getScreenWidth())
        getScreenWidth()
    }

    onMounted(initializeScreenWidth)
    onBeforeUnmount(() => {
        window.removeEventListener('resize', () => getScreenWidth())
    })

    return {
        screenWidth,
    }
}
