import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { HttpError } from './HttpError.class'

const JSON_HEADER = 'application/json'
const FORMDATA_HEADER = 'multipart/form-data'

export class Http {
    private _provider: AxiosInstance

    constructor(baseURL: string) {
        this._provider = axios
        this._provider.defaults.baseURL = baseURL

        this._provider.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                const response = error.response
                const message = response?.data.message
                const code = response?.data.code

                if (response) {
                    const status = response.status
                    const url = response.config.url

                    if (
                        (status === 401 || status === 403) &&
                        !window.location.href.includes('/auth')
                    ) {
                        window.location.href = '/auth'
                    }

                    if (status === 418) {
                        const validation_errors = Object.values(response.data.errors) as string[][]
                        let parsed_errors = [] as string[]

                        validation_errors.forEach((validation_error_array: string[]) => {
                            parsed_errors = parsed_errors.concat(validation_error_array)
                        })

                        return Promise.reject(parsed_errors)
                    }

                    return Promise.reject({ message, status, url, code })
                }

                return Promise.reject(error)
            }
        )
    }

    setAuthorization(token: string, token_type: string): void {
        this._provider.defaults.headers.common.Authorization = `${token_type} ${token}`
    }

    removeAuthorization(): void {
        this._provider.defaults.headers.common.Authorization = ''
    }

    token(): string {
        return this._provider.defaults.headers.common.Authorization
    }

    get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        this._provider.defaults.headers['Content-Type'] = JSON_HEADER
        return this._provider.get(url, config)
    }

    post(url: string, data: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        this._provider.defaults.headers['Content-Type'] = JSON_HEADER
        return this._provider.post(url, data, config)
    }

    put(url: string, data: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        this._provider.defaults.headers['Content-Type'] = JSON_HEADER
        return this._provider.put(url, data, config)
    }

    delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        this._provider.defaults.headers['Content-Type'] = JSON_HEADER
        return this._provider.delete(url, config)
    }

    postFormData(url: string, data: FormData, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        this._provider.defaults.headers['Content-Type'] = FORMDATA_HEADER
        return this._provider.post(url, data, config)
    }

    putFormData(url: string, data: FormData, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        this._provider.defaults.headers['Content-Type'] = FORMDATA_HEADER
        return this._provider.put(url, data, config)
    }

    get authorizationHeader(): string {
        return this._provider.defaults.headers.common.Authorization
    }
}

export const http = new Http(process.env.VUE_APP_BASE_URL!)
