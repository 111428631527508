import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authMiddleware } from '@/middlewares/auth_middleware'

import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AuthLayout from '@/layouts/Auth.vue'
import RegisterLayout from '@/layouts/Register.vue'
import A4Landscape from '@/layouts/A4Landscape.vue'
import FullLayout from '@/layouts/FullLayout.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'default',
        component: DefaultLayout,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/calculator',
                name: 'Calculator',
                meta: { requiresAuth: true },
                component: () =>
                    import(/* webpackChunkName: "calculator" */ '@/views/Calculator.vue'),
            },
            {
                path: '/reconciliation-reports',
                name: 'ReconciliationReports',
                component: () =>
                    import(
                        /* webpackChunkName: "reconciliation-reports" */ '@/views/ReconcilationReports/index.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/pretensions',
                name: 'Pretensions',
                component: () =>
                    import(/* webpackChunkName: "pretensions" */ '@/views/Pretensions/index.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/pretensions/create',
                name: 'PretensionsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "pretensions-create" */ '@/views/Pretensions/Create.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/complaints/create',
                name: 'ComplaintsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "complaints-create" */ '@/views/Pretensions/ComplaintCreate.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/pretensions/:pretension_id/update',
                name: 'PretensionUpdate',
                component: () =>
                    import(
                        /* webpackChunkName: "pretensions-update" */ '@/views/Pretensions/Update.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/pretensions/:pretension_id',
                name: 'PretensionId',
                component: () =>
                    import(/* webpackChunkName: "pretensions-id" */ '@/views/Pretensions/id.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/complaints/:complaint_id',
                name: 'ComplaintId',
                component: () =>
                    import(
                        /* webpackChunkName: "complaint-id" */ '@/views/Pretensions/Complaint.vue'
                    ),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/history',
                name: 'History',
                component: () => import(/* webpackChunkName: "history" */ '@/views/History.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/',
                name: 'Create',
                component: () => import(/* webpackChunkName: "create" */ '@/views/OrderCreate.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/profile',
                name: 'Profile',
                component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/profile/users',
                name: 'ProfileUsers',
                component: () => import(/* webpackChunkName: "profileusers" */ '@/views/Users.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/invoice/:invoice_id',
                name: 'InvoiceId',
                component: () =>
                    import(/* webpackChunkName: "invoice-v2" */ '@/views/InvoiceId.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/invoice/:invoice_id/update',
                name: 'UpdateInvoiceId',
                component: () =>
                    import(/* webpackChunkName: "invoice-update" */ '@/views/UpdateLTLInvoice.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/order/ltl/:order_id',
                name: 'LTLOrderId',
                component: () => import(/* webpackChunkName: "order" */ '@/views/LTLOrderId.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/order/ltl/:order_id/update',
                name: 'UpdateLTLOrder',
                component: () =>
                    import(/* webpackChunkName: "update-ltl-order" */ '@/views/UpdateLTLOrder.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/order/ftl/:order_id',
                name: 'FTLOrderId',
                component: () => import(/* webpackChunkName: "order" */ '@/views/FTLOrderId.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/ftl/bid/:bid_id',
                name: 'FTLBid',
                component: () => import(/* webpackChunkName: "bid" */ '@/views/FTLBid.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/express/:order_id',
                name: 'ExpressOrder',
                component: () =>
                    import(/* webpackChunkName: "express-order" */ '@/views/ExpressOrder.vue'),
                meta: { requiresAuth: true },
            },
            // {
            //     path: '/chat',
            //     name: 'Chat',
            //     component: () => import(/* webpackChunkName: "chat" */ '@/views/Chat.vue'),
            //     meta: { requiresAuth: true },
            // },
        ],
    },
    {
        path: '/',
        name: 'A4Landscape',
        component: A4Landscape,
        children: [
            {
                path: '/waybill/:order_id/print',
                name: 'WaybillPrint',
                component: () =>
                    import(/* webpackChunkName: "waybill-print" */ '@/views/WaybillPrint.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/ttn/:order_id/print',
                name: 'TtnPrint',
                component: () => import(/* webpackChunkName: "ttn-print" */ '@/views/TTNPrint.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/',
        name: 'FullLayout',
        component: FullLayout,
        children: [
            {
                path: '/tracking/:order_id',
                name: 'FullLayout',
                component: () =>
                    import(/* webpackChunkName: "full-layout" */ '@/views/Tracking.vue'),
            },
        ],
    },
    {
        path: '/',
        name: 'auth',
        component: AuthLayout,
        children: [
            {
                path: '/auth',
                name: 'Auth',
                component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth.vue'),
            },
        ],
    },
    {
        path: '/',
        name: 'register',
        component: RegisterLayout,
        children: [
            {
                path: '/register',
                name: 'Register',
                component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(authMiddleware)

export default router
