<template>
    <div>
        <router-view />
    </div>
</template>

<style scoped>
@page {
    size: A4 landscape;
    margin: 0;
}
</style>
