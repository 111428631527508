import { AxiosResponse } from 'axios'

import { http } from '@/core/classes/HTTP.class'

import {
    FTLExcelParams,
    FTLOrderQueryFilters,
    LTLExcelParams,
    LTLQueryFilters,
} from '../types/History.types'
import {
    LTLInvoiceResponseT,
    LTLInvoicesResponseT,
    LTLOrdersResponseT,
    LTLOrderResponseT,
    FTLOrdersResponseT,
    FTLOrderResponseT,
    LTLInvoiceStatusesResponseT,
    LTLInvoiceForPrintResponseT,
    LTLInvoicePlacesForPrintResponseT,
    FTLInvoiceForPrintResponseT,
    LTLOrderForUpdateResponseT,
    LTLArrivingInvoicesResponseT,
    LTLSendedInvoicesResponseT,
    SendToEmailsResponseT,
    LTLOrdersDownloadExcelResponseT,
    FTLOrdersDownloadExcelResponseT,
    getTemperatureRegimesResponseT,
    LTLOrderPlacesForPrintResponseT,
    LTLOrderIdDownloadExcelResponseT,
} from '../types/Responses/Invoices.responses'
import { ServerResponse } from '../types/Responses/common.responses'
import { SendToEmailsPayload, UpdateInvoicePayloadT } from '../types/Invoices.types'

enum InvoicesRepositoryRoutesEnum {
    'LTL_INVOICES' = '/v2/logistics-info',
    'LTL_INVOICES_EXCEL' = '/v2/logistics-info/export',
    'FTL_ORDERS_EXCEL' = '/v2/ftl/order/export',
    'LTL_ORDER_ID_EXCEL' = '/v2/logistics-info/export',
    'LTL_INVOICE' = '/v2/logistics-info',
    'LTL_INVOICE_FOR_PRINT' = '/pdf',
    'INVOICE_FOR_PRINT' = '/v2/logistics-info/print',
    'FTL_INVOICE_FOR_PRINT' = '/v2/ftl/pdf',
    'LTL_INVOICES_FOR_PRINT' = '/invoices',
    'LTL_INVOICE_STATUSES' = '/invoice-status',
    'LTL_ORDERS' = '/v2/orders',
    'LTL_ORDER_FOR_UPDATE' = '/v2/orders',
    'LTL_ORDER' = '/v2/logistics-info/order',
    'LTL_ORDER_FOR_PRINT' = '/v2/logistics-info/order/print',
    'FTL_ORDERS' = '/v2/ftl-order',
    'FTL_ORDER' = '/v2/ftl-order',
    'CREATE_LTL_ORDER' = '/v2/orders',
    'CREATE_FTL_ORDER' = '/v2/ftl-order',
    'UPDATE_ORDER' = '/v2/orders',
    'INVOICE_PLACES' = '/barcode_with_places',
    'CANCEL_LTL_ORDER' = '/v2/orders',
    'CANCEL_FTL_ORDER' = '/v2/ftl/status',
    'CANCEL_INVOICE' = '/v2/logistics-info',
    'CONFIRM_FTL_ORDER' = '/v2/ftl/confirm-order',
    'CREATE_EXCEL' = '/v2/orders/excel',
    'DELETE_SENDER' = '/sender',
    'DELETE_RECEIVER' = '/receiver',
    'UPLOAD_FILES' = '/file/upload-files',
    'DELETE_FILE' = '/file',
    'LTL_ARRIVING_INVOICES' = '/v2/logistics-info/receiver',
    'LTL_SENDED_INVOICES' = '/v2/logistics-info/sender',
    'SEND_TO_EMAILS' = '/send-invoice',
    'GET_TEMPERATURE_REGIMES' = '/temperature-regime-types',
}

const GATEWAY_URL = process.env.VUE_APP_GATEWAY_URL

class InvoicesRepository {
    getLTLArrivingInvoices(
        params: LTLQueryFilters
    ): Promise<AxiosResponse<LTLArrivingInvoicesResponseT>> {
        return http.get(InvoicesRepositoryRoutesEnum.LTL_ARRIVING_INVOICES, { params })
    }

    getLTLSendedInvoices(
        params: LTLQueryFilters
    ): Promise<AxiosResponse<LTLSendedInvoicesResponseT>> {
        return http.get(InvoicesRepositoryRoutesEnum.LTL_SENDED_INVOICES, { params })
    }

    getLTLInvoices(params: LTLQueryFilters): Promise<AxiosResponse<LTLInvoicesResponseT>> {
        return http.get(InvoicesRepositoryRoutesEnum.LTL_INVOICES, {
            params,
        })
    }

    getLTLOrders(params: LTLQueryFilters): Promise<AxiosResponse<LTLOrdersResponseT>> {
        return http.get(InvoicesRepositoryRoutesEnum.LTL_ORDERS, {
            params,
        })
    }

    exportExcelLTLInvoices(
        params: LTLExcelParams
    ): Promise<AxiosResponse<LTLOrdersDownloadExcelResponseT>> {
        return http.get(InvoicesRepositoryRoutesEnum.LTL_INVOICES_EXCEL, {
            responseType: 'blob',
            params: { ...params },
        })
    }

    exportExcelFTLOrders(
        params: FTLExcelParams
    ): Promise<AxiosResponse<FTLOrdersDownloadExcelResponseT>> {
        return http.get(InvoicesRepositoryRoutesEnum.FTL_ORDERS_EXCEL, {
            responseType: 'blob',
            params: { ...params },
        })
    }

    exportExcelLTLOrderId(
        orderId: number
    ): Promise<AxiosResponse<LTLOrderIdDownloadExcelResponseT>> {
        return http.get(InvoicesRepositoryRoutesEnum.LTL_ORDER_ID_EXCEL, {
            responseType: 'blob',
            params: { orderId },
        })
    }

    getLTLInvoice(invoice_id: string): Promise<AxiosResponse<LTLInvoiceResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.LTL_INVOICE}/${invoice_id}`)
    }

    getInvoiceForPrint(invoice_id: string): Promise<AxiosResponse<LTLInvoiceResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.INVOICE_FOR_PRINT}/${invoice_id}`)
    }

    getLTLOrder(order_id: number, params: any): Promise<AxiosResponse<LTLOrderResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.LTL_ORDER}/${order_id}`, { params })
    }

    getLTLOrderForPrint(order_id: number): Promise<AxiosResponse<LTLOrderResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.LTL_ORDER_FOR_PRINT}/${order_id}`)
    }

    getLTLOrderForUpdate(order_id: number): Promise<AxiosResponse<LTLOrderForUpdateResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.LTL_ORDER_FOR_UPDATE}/${order_id}`)
    }

    getLTLInvoiceStatuses(
        invoice_number: string
    ): Promise<AxiosResponse<LTLInvoiceStatusesResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.LTL_INVOICE_STATUSES}/${invoice_number}`)
    }

    getFTLOrders(params: FTLOrderQueryFilters): Promise<AxiosResponse<FTLOrdersResponseT>> {
        return http.get(InvoicesRepositoryRoutesEnum.FTL_ORDERS, { params })
    }

    getFTLOrder(order_id: number): Promise<AxiosResponse<FTLOrderResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.FTL_ORDER}/${order_id}`)
    }

    getLTLInvoiceForPrint(
        invoice_number: string
    ): Promise<AxiosResponse<ServerResponse<LTLInvoiceForPrintResponseT>>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.LTL_INVOICE_FOR_PRINT}/${invoice_number}`)
    }

    getFTLInvoiceForPrint(
        invoice_number: string
    ): Promise<AxiosResponse<FTLInvoiceForPrintResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.FTL_INVOICE_FOR_PRINT}/${invoice_number}`)
    }

    getLTLInvoicesForPrint(
        order_id: number
    ): Promise<AxiosResponse<ServerResponse<LTLInvoiceForPrintResponseT[]>>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.LTL_INVOICES_FOR_PRINT}/${order_id}`)
    }

    createLTLOrder(order_payload: any): Promise<AxiosResponse<void>> {
        return http.post(InvoicesRepositoryRoutesEnum.CREATE_LTL_ORDER, order_payload)
    }

    createFTLOrder(order_payload: any): Promise<AxiosResponse<void>> {
        return http.post(InvoicesRepositoryRoutesEnum.CREATE_FTL_ORDER, order_payload)
    }

    uploadFiles(form_data: FormData): Promise<AxiosResponse<any>> {
        return http.postFormData(InvoicesRepositoryRoutesEnum.UPLOAD_FILES, form_data)
    }

    deleteFile(id: number): Promise<AxiosResponse<void>> {
        return http.delete(`${InvoicesRepositoryRoutesEnum.DELETE_FILE}/${id}`)
    }

    updateLTLOrder(order_id: number, order_payload: any): Promise<AxiosResponse<void>> {
        return http.post(
            `${InvoicesRepositoryRoutesEnum.UPDATE_ORDER}/${order_id}/invoice`,
            order_payload
        )
    }

    sendInvoice(
        pdf: string,
        shouldBeSentToOwner: boolean,
        emails: string[]
    ): Promise<AxiosResponse<void>> {
        return http.post(`/send-invoice`, { pdf, shouldBeSentToOwner, emails })
    }

    getInvoicePlaces(
        invoice_id: number
    ): Promise<AxiosResponse<ServerResponse<LTLInvoicePlacesForPrintResponseT>>> {
        return http.get(`${GATEWAY_URL}/print/api/ltl-invoice-places/${invoice_id}`, {
            responseType: 'blob',
        })
    }

    getOrderPlaces(
        order_id: number
    ): Promise<AxiosResponse<ServerResponse<LTLOrderPlacesForPrintResponseT>>> {
        return http.get(`${GATEWAY_URL}/print/api/ltl-order-places/${order_id}`, {
            responseType: 'blob',
        })
    }

    cancelLTLOrder(order_id: number): Promise<AxiosResponse<void>> {
        return http.post(
            `${InvoicesRepositoryRoutesEnum.CANCEL_LTL_ORDER}/${order_id}/cancel`,
            null
        )
    }

    cancelFTLOrder(order_id: number): Promise<AxiosResponse<void>> {
        return http.put(`${InvoicesRepositoryRoutesEnum.CANCEL_FTL_ORDER}/${order_id}`, {
            status_code: 'canceled',
        })
    }

    cancelInvoice(invoice_id: number): Promise<AxiosResponse<void>> {
        return http.post(`${InvoicesRepositoryRoutesEnum.CANCEL_INVOICE}/${invoice_id}/cancel`, {
            sourceId: 10,
        })
    }

    confirmFTLOrder(order_id: number): Promise<AxiosResponse<void>> {
        return http.post(`${InvoicesRepositoryRoutesEnum.CONFIRM_FTL_ORDER}/${order_id}`, null)
    }

    createExcel(payload_formdata: FormData): Promise<AxiosResponse<void>> {
        return http.postFormData(InvoicesRepositoryRoutesEnum.CREATE_EXCEL, payload_formdata)
    }

    sendToEmails(payload: SendToEmailsPayload): Promise<AxiosResponse<SendToEmailsResponseT>> {
        return http.post(InvoicesRepositoryRoutesEnum.SEND_TO_EMAILS, payload)
    }

    updateInvoice(
        invoice_id: number,
        payload: UpdateInvoicePayloadT
    ): Promise<AxiosResponse<void>> {
        return http.put(`/v2/logistics-info/${invoice_id}`, payload)
    }

    getTemperatureRegimes(): Promise<AxiosResponse<getTemperatureRegimesResponseT>> {
        return http.get(`${InvoicesRepositoryRoutesEnum.GET_TEMPERATURE_REGIMES}`)
    }
}

export const invoicesRepository = new InvoicesRepository()
