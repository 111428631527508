
import SGradientsProvider from '@/common/components/SGradientsProvider/index.vue'
import SButton from '@/common/components/SButton/index.vue'

// composable
import useImpersonate from '@/common/composable/useImpersonate'

export default {
    components: {
        's-gradients-provider': SGradientsProvider,
        's-button': SButton,
    },
    setup() {
        useImpersonate()
    },
}
