import SurveyI from '../interfaces/Survey.interface'
import surveyService from '../services/Survey.service'
import { ServerResponse } from '../types/Responses/common.responses'
import { SendAnswerPayloadT, SurveyT } from '../types/Survey.types'

export class SurveyRealisation implements SurveyI {
    async getSurvey(): Promise<ServerResponse<SurveyT>> {
        const response = await surveyService.getSurvey()
        return response
    }

    async sendAnswer(payload: SendAnswerPayloadT): Promise<void> {
        const response = await surveyService.sendAnswer(payload)
        return response
    }
}
