import { AuthorizationStateT } from '@/core/types/Authorization.types'

export const AUTHORIZATION_STORAGE_TOKEN = 'spark-cabinet-redesigned-auth'
export const USER_STORAGE_TOKEN = 'spark-cabinet-redesigned-user-info'

export const DEFAULT_AUTHORIZATION_STATE: AuthorizationStateT = {
    access_token: '',
    refresh_token: '',
    token_type: '',
    expires_in: 0,
}

export const IMPERSONATE_STORAGE_TOKEN = 'spark-cabinet-redesigned-impersonate'
