import { AuthorizationI } from '@/core/interfaces/Authorization.interface'

import {
    AuthorizationCredentialsT,
    AuthorizationConfirmRecoveryPayloadT,
    AuthorizationStateT,
    RegisterPayloadT,
    GetInfoResponseT,
} from '@/core/types/Authorization.types'

import {
    AuthorizationConfirmRecoveryResponseT,
    AuthorizationRecoveryResponseT,
    BillingPeriodsResponseT,
    BusinessModelsResponseT,
} from '@/core/types/Responses/Authorization.responses'

import { authorizationService } from '../services/Authorization.service'

export class AuthorizationRealisation implements AuthorizationI {
    authorize(credentials: AuthorizationCredentialsT): Promise<AuthorizationStateT> {
        return authorizationService.authorize(credentials)
    }

    recovery(email: string): Promise<AuthorizationRecoveryResponseT> {
        return authorizationService.recovery(email)
    }

    confirmRecoveryCode(
        payload: AuthorizationConfirmRecoveryPayloadT
    ): Promise<AuthorizationConfirmRecoveryResponseT> {
        return authorizationService.confirmRecoveryCode(payload)
    }

    getBusinessModels(): Promise<BusinessModelsResponseT> {
        return authorizationService.getBusinessModels()
    }

    getBillingPeriods(): Promise<BillingPeriodsResponseT> {
        return authorizationService.getBillingPeriods()
    }

    registerCompany(payload: RegisterPayloadT): Promise<void> {
        return authorizationService.registerCompany(payload)
    }

    getInfo(): Promise<GetInfoResponseT> {
        return authorizationService.getInfo()
    }
}
