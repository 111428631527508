import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import { ReceiverPayloadT } from '@/core/types/common.types'

import { ServerResponse } from '../types/Responses/common.responses'
import {
    PayersResponse,
    ReceiverCreateResponse,
    ReceiversGetAllFTLResponse,
    ReceiversGetAllLTLResponse,
    ReceiversGetByIdResponse,
    ReceiversGetPeriodsLTLResponse,
    ReceiversSearchResponse,
} from '../types/Responses/Receivers.responses'

enum ReceiversRepositoryRoutesEnum {
    'GET_ALL_LTL' = '/receiver',
    'GET_ALL_FTL' = '/receiver',
    'GET_PAYERS' = '/companies/payer-companies',
    'GET_BY_ID' = '/receiver',
    'CREATE' = '/receiver',
    'UPDATE' = '/v2/receivers',
    'UPDATE_LTL_CREATE' = '/receiver',
    'DELETE' = '/receiver',
    'SEARCH' = '/receiver/search',
    'GET_PERIODS' = '/order-periods',
}

export class ReceiversRepository {
    /**
     * Получение Получателей для LTL заказа
     * @param page Страница
     * @param sender_city_id ID города выбранного Отправителя
     * @returns Promise<AxiosResponse<ServerResponse<ReceiversGetAllLTLResponse>>>
     */
    getAllLTL(
        page: number,
        sender_city_id: number
    ): Promise<AxiosResponse<ServerResponse<ReceiversGetAllLTLResponse>>> {
        const params = {
            page_number: page,
        }

        return http.get(`${ReceiversRepositoryRoutesEnum.GET_ALL_LTL}/${sender_city_id}`, {
            params,
        })
    }

    /**
     * Получение списка периодов
     * @returns Promise<AxiosResponse<ServerResponse<ReceiversGetPeriodsLTLResponse>>>
     */
    getPeriods(): Promise<AxiosResponse<ServerResponse<ReceiversGetPeriodsLTLResponse>>> {
        return http.get(ReceiversRepositoryRoutesEnum.GET_PERIODS)
    }

    /**
     * Получение Плательщиков для LTL заказа
     * @param company_id ID компании
     * @param page Страница
     * @returns Promise<AxiosResponse<any>>
     */
    getPayers(
        company_id: number,
        page: number
    ): Promise<AxiosResponse<ServerResponse<PayersResponse>>> {
        return http.get(`${ReceiversRepositoryRoutesEnum.GET_PAYERS}/${company_id}`, {
            params: { page },
        })
    }

    /**
     * Получение Получателей для FTL заказа
     * @param page Страница
     * @returns Promise<AxiosResponse<ReceiversGetAllFTLResponse>>
     */
    getAllFTL(
        page: number,
        city_id?: number
    ): Promise<AxiosResponse<ServerResponse<ReceiversGetAllFTLResponse>>> {
        const params = {
            page_number: page,
            city_id,
        }

        // return http.get(ReceiversRepositoryRoutesEnum.GET_ALL_FTL, { params })
        return http.get(`${ReceiversRepositoryRoutesEnum.GET_ALL_FTL}`, { params })
    }

    /**
     * Получение получателя по ID
     * @param receiver_id ID получателя
     * @returns Promise<AxiosResponse<ReceiversGetByIdResponse>>
     */
    getById(receiver_id: number): Promise<AxiosResponse<ReceiversGetByIdResponse>> {
        return http.get(`${ReceiversRepositoryRoutesEnum.GET_BY_ID}/${receiver_id}`)
    }

    getList(params: {
        page_number: number
        city_id?: number
    }): Promise<AxiosResponse<{ data: ReceiversGetAllFTLResponse }>> {
        return http.get(`${ReceiversRepositoryRoutesEnum.GET_ALL_FTL}`, { params })
    }

    /**
     * Создание получателя
     * @param receiver_payload Данные для создания получателя
     * @returns Promise<AxiosResponse<void>>
     */
    create(
        receiver_payload: ReceiverPayloadT
    ): Promise<AxiosResponse<ServerResponse<ReceiverCreateResponse>>> {
        return http.post(ReceiversRepositoryRoutesEnum.CREATE, receiver_payload)
    }

    /**
     * Редактирование получателя
     * @param receiver_id ID получателя
     * @param receiver_payload Данные для редактирования получателя
     * @returns Promise<AxiosResponse<void>>
     */
    update(receiver_id: number, receiver_payload: ReceiverPayloadT): Promise<AxiosResponse<void>> {
        return http.put(`${ReceiversRepositoryRoutesEnum.UPDATE}/${receiver_id}`, receiver_payload)
    }

    /**
     * Редактирование получателя при создании LTL-заказа
     * @param receiver_id ID получателя
     * @param receiver_payload Данные для редактирования получателя
     * @returns Promise<AxiosResponse<void>>
     */
    updateLTL(
        receiver_id: number,
        receiver_payload: ReceiverPayloadT
    ): Promise<AxiosResponse<void>> {
        return http.put(
            `${ReceiversRepositoryRoutesEnum.UPDATE_LTL_CREATE}/${receiver_id}`,
            receiver_payload
        )
    }

    /**
     * Удаление получателя
     * @param receiver_id ID получателя
     * @returns Promise<AxiosResponse<void>>
     */
    delete(receiver_id: number): Promise<AxiosResponse<void>> {
        return http.delete(`${ReceiversRepositoryRoutesEnum.DELETE}/${receiver_id}`)
    }

    /**
     * Поиск получателей
     * @param search_query (наименование/телефон/улица) получателя
     * @returns Promise<AxiosResponse<ReceiversSearchResponse>>
     */
    search(
        search_query: string,
        exact_city_id?: number
    ): Promise<AxiosResponse<ServerResponse<ReceiversSearchResponse>>> {
        return http.post(`${ReceiversRepositoryRoutesEnum.SEARCH}`, {
            param: search_query,
            exact_city_id,
        })
    }

    searchLTL(
        search_query: string,
        sender_city_id: number
    ): Promise<AxiosResponse<ServerResponse<ReceiversSearchResponse>>> {
        return http.post(`${ReceiversRepositoryRoutesEnum.SEARCH}`, {
            param: search_query,
            city_id: sender_city_id,
        })
    }
}
