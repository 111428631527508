import { AxiosResponse } from 'axios'

import { http } from '@/core/classes/HTTP.class'

import {
    AuthorizationCredentialsT,
    AuthorizationStateT,
    AuthorizationConfirmRecoveryPayloadT,
    RegisterPayloadT,
    GetInfoResponseT,
} from '@/core/types/Authorization.types'

import {
    AuthorizationConfirmRecoveryResponseT,
    AuthorizationRecoveryResponseT,
    BillingPeriodsResponseT,
    BusinessModelsResponseT,
} from '@/core/types/Responses/Authorization.responses'

const AUTH_BASE_URL = process.env.VUE_APP_GATEWAY_URL

class AuthorizationRepository {
    signin(credentials: AuthorizationCredentialsT): Promise<AxiosResponse<AuthorizationStateT>> {
        return http.post(`${AUTH_BASE_URL}/oauth/token`, credentials)
    }

    recovery(email: string): Promise<AxiosResponse<AuthorizationRecoveryResponseT>> {
        return http.post(`${AUTH_BASE_URL}/api/recovery/code`, { email })
    }

    confirmRecoveryCode(
        payload: AuthorizationConfirmRecoveryPayloadT
    ): Promise<AxiosResponse<AuthorizationConfirmRecoveryResponseT>> {
        return http.post(`${AUTH_BASE_URL}/api/recovery/confirm-code`, payload)
    }

    getBusinessModels(): Promise<AxiosResponse<BusinessModelsResponseT>> {
        return http.get('/v2/company/business-models')
    }

    getBillingPeriods(): Promise<AxiosResponse<BillingPeriodsResponseT>> {
        return http.get('/billing-periods')
    }

    registerCompany(payload: RegisterPayloadT): Promise<AxiosResponse<void>> {
        return http.post('/v2/companies/store-from-cabinet', payload)
    }

    getInfo(): Promise<AxiosResponse<GetInfoResponseT>> {
        return http.get(`${AUTH_BASE_URL}/gateway/users/info`)
    }
}

export const authorizationRepository = new AuthorizationRepository()
