export class DIContainer {
    private _realisations: Record<string, any>

    constructor() {
        this._realisations = {}
    }

    add<Type>(interface_key: string, realisation: Type): void {
        this._realisations[interface_key] = realisation
    }

    interface<Type>(interface_key: string): Type {
        const findedInterface = this._realisations[interface_key]

        if (!findedInterface) {
            throw new Error(
                `[DI-CONTAINER] Interface realisation with key ${interface_key} not found`
            )
        }

        return findedInterface
    }
}
