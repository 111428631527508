
import { defineComponent } from 'vue'

// components
import SButton from '@/common/components/SButton/index.vue'

// constants
import { MINUTES_TOKENS } from '@/core/constants/common.constants'

// composable
import useImpersonate from '@/common/composable/useImpersonate'

export default defineComponent({
    components: {
        's-button': SButton,
    },
    setup() {
        const { impersonate_state, impersonateQuit } = useImpersonate(false)
        return { impersonate_state, impersonateQuit, MINUTES_TOKENS }
    },
})
