import { SurveyRepository } from '../repositories/Survey.repository'
import { ServerResponse } from '../types/Responses/common.responses'
import { SendAnswerPayloadT, SurveyT } from '../types/Survey.types'

const surveyRepository = new SurveyRepository()

const surveyService = {
    async getSurvey(): Promise<ServerResponse<SurveyT>> {
        const response = await surveyRepository.getSurvey()
        return response.data
    },

    async sendAnswer(payload: SendAnswerPayloadT): Promise<void> {
        const response = await surveyRepository.sendAnswer(payload)
        return response.data
    }
}

export default surveyService
