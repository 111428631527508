
import { defineComponent } from 'vue'

import iInfo from '@/assets/icons/Info.svg'

import ozonLogo from '@/assets/icons/order-types/ozon.svg'

export default defineComponent({
    components: {
        'icon-info': iInfo,
        'icon-ozon': ozonLogo,
    },
    props: {
        type: {
            type: String,
            default: 'LTL',
        },
        title: {
            type: String,
            default: 'Title',
        },
        subtitle: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        titleMaxWidth: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        img: {
            type: String,
            default: null,
        },
        imageTop: {
            type: String,
            default: null,
        },
        imageLeft: {
            type: String,
            default: null,
        },
        imageBottom: {
            type: String,
            default: null,
        },
        imageRight: {
            type: String,
            default: null,
        },
        imageWidth: {
            type: String,
            default: null,
        },
        imageHeight: {
            type: String,
            default: null,
        },
        isRelativeContent: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['open-ltl', 'open-ftl', 'open-express', 'open-form'],
    setup(props, { emit }) {
        const getImage = (image_name: string | null) => {
            if (!image_name) return null
            return require(`@/assets/images/order-types/${image_name}`)
        }

        const onClick = () => {
            if (props.disabled) return

            if (props.type === 'LTL') {
                emit('open-ltl')
                return
            }

            if (props.type === 'FTL') {
                emit('open-ftl')
                return
            }

            if (props.type === 'Express') {
                emit('open-express')
                return
            }

            if (props.type === 'Purple') {
                emit('open-form')
                return
            }
        }

        return { getImage, onClick }
    },
})
