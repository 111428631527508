import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "STextArea__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["STextArea", { invalid: _ctx.isInvalid }],
    style: `height: ${_ctx.state.height}px`
  }, [
    (_ctx.$slots.icon)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "icon")
        ]))
      : _createCommentVNode("", true),
    _createVNode("textarea", {
      class: { 'with-icon': _ctx.$slots.icon },
      placeholder: _ctx.placeholder,
      value: _ctx.value,
      disabled: _ctx.disabled,
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emitEnter && _ctx.emitEnter(...args)), ["enter"])),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.emitInput && _ctx.emitInput(...args))),
      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args)))
    }, null, 42, ["placeholder", "value", "disabled"])
  ], 6))
}