<template>
    <div
        :class="{
            'DefaultLayout': $route.name !== 'Calculator',
            'CalculatorLayout': $route.name === 'Calculator'
        }"
    >
        <s-sidebar></s-sidebar>

        <div id="view">
            <impersonate-panel v-if="impersonate_state.is_active" />
            <survey-panel v-if="!impersonate_state.is_active" />

            <router-view />
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount } from 'vue'

import SSideBar from '@/common/components/SSideBar/index.vue'
import ImpersonatePanel from '@/common/components/ImpersonatePanel/index.vue'
import SurveyPanel from '@/common/components/SurveyPanel/index.vue'

import useImpersonate from '@/common/composable/useImpersonate'
import useJiraWidget from '@/common/composable/useJiraWidget'

export default defineComponent({
    components: {
        's-sidebar': SSideBar,
        'impersonate-panel': ImpersonatePanel,
        'survey-panel': SurveyPanel,
    },
    setup() {
        const { impersonate_state } = useImpersonate(false)
        const jira_widget = useJiraWidget()

        onMounted(jira_widget.install)
        onBeforeUnmount(jira_widget.destroy)

        return { impersonate_state }
    },
})
</script>
